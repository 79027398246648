<template>
  <v-dialog v-model="showingCampaignEventModal" fullscreen hide-overlay>
    <v-card>
      <v-card-text v-if="loading">
        Cargando...
        <v-progress-linear indeterminate color="black" class="mb-0"></v-progress-linear>
      </v-card-text>

      <v-toolbar class="tool-bar">
        <v-btn icon color="primary" @click="close()">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h5 grey lighten-2">{{
          `Evento de Campaña #${campaign_event_details.id} - ${campaign_event_details.name}`
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <v-card-subtitle class="pt-4 ml-10">
          {{
            `Creada en ${campaign_event_details.created_at}`
          }}
        </v-card-subtitle>
      </div>

      <v-divider></v-divider>

      <v-card-text v-if="!loading">
        <v-row>
          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="primary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiAccountOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <span class="text--primary text-xl font-weight-bold">
                  Cantidad de contactos asociados
                </span>
                <p
                  class="text-s mb-0"
                >
                  {{ campaign_event_details.total_contacts }}
                </p>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="accent" rounded class="elevation-1">
                <v-icon 
                v-if="campaign_event_details.tags && campaign_event_details.tags.length"
                dark 
                color="white" 
                size="30">
                  {{ icons.mdiTagMultiple }}
                </v-icon>

                <v-icon 
                v-else-if="campaign_event_details.batches && campaign_event_details.batches.length"
                dark 
                color="white" 
                size="30">
                  {{ icons.mdiAccountMultipleOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3" v-if="campaign_event_details.tags && campaign_event_details.tags.length">
                <span class="text--primary text-xl font-weight-bold">
                  Tags asociados
                </span>
                <div style="overflow-y: auto; max-height: 105px">
                  <div
                    v-for="(item, idx) in campaign_event_details.tags"
                    :key="`campaign_item_${idx}`"
                  >
                    <p class="text-s mb-0">{{ item }}</p>
                  </div>
                </div>
              </div>
              <div class="ms-3" v-else-if="campaign_event_details.batches && campaign_event_details.batches.length">
                <span class="text--primary text-xl font-weight-bold">
                  Lotes asociados
                </span>
                <div style="overflow-y: auto; max-height: 105px">
                  <div
                    v-for="(item, idx) in campaign_event_details.batches"
                    :key="`campaign_item_${idx}`"
                  >
                    <p class="text-s mb-0">{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="secondary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCalendarQuestion }}
                </v-icon>
              </v-avatar>

              <div class="ms-3" v-if="campaign_event_details.event">
                <span class="text--primary text-xl font-weight-bold">
                  Detalles del evento
                </span>

                <p class="text-s mb-0">
                  Fecha de inicio: {{ campaign_event_details.event.datetime.slice(0,11) }}
                </p>
                <p class="text-s mb-0">
                  Hora de inicio: {{ campaign_event_details.event.datetime.slice(11, 17) }}
                </p>
                <p class="text-s mb-0">
                  Estado: <span :class="`${statusColorParser[campaign_event_details.event.status.id]}--text`">{{ campaign_event_details.event.status.label }}</span>
                </p>
                <p class="text-s mb-0">
                  Recordar antes del evento: {{ campaign_event_details.event.remind ? 'Si' : 'No' }}
                </p>
                <p class="text-s mb-0">
                  Enviar notificación al email: {{ campaign_event_details.event.notify ? 'Si' : 'No' }}
                </p>
                <div v-if="campaign_event_details.event.description">
                  <p v-if="campaign_event_details.event.description.length <= 40">Descripción: {{ campaign_event_details.event.description }}</p>
                  <div v-else class="d-flex align-center justify-start">
                    <p style="width: 80%;">
                      Descripción: {{ campaign_event_details.event.description.substring(0, 37) + '...' }}
                    </p>
                    <v-btn icon x-small @click="showCompleteDescription(campaign_event_details.event.description)" style="width: 20%;">
                      <v-icon color="primary">{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </div>
                </div>
                <span></span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="searchQuery"
              @keyup="handleSearch"
              placeholder="Buscar..."
              outlined
              hide-details
              dense
              class="user-search me-3 mb-4"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="campaign_event_contacts"
        :page="current_page"
        :loading="loadingContacts"
        :server-items-length="totalEventContactListTable"
        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
        @update:page="changingCampaignEventContactsPage($event)"
        @update:items-per-page="changingCampaignEventContactsItemsPerPage($event)"
      >
      <!-- Name -->
        <template #[`item.name`]="{ item }">
          <span class="font500-rgb125">{{ item.name }}</span>
        </template>

      <!-- Email -->
        <template #[`item.email`]="{ item }">
          <span class="font500-rgb125">{{ item.email }}</span>
        </template>
      </v-data-table>

      <!-- Show complete description Text -->
      <template>
          <v-dialog v-model="description_modal" max-width="500">
            <v-card>
              <v-card-text>
                {{ description }}
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
    </v-card>
  </v-dialog>
</template>

<script>
const debounce = require("debounce");
import { ref } from "@vue/composition-api";
import AlertPopUp from "@/components/AlertPopUp.vue";

import {
  mdiClose,
  mdiTagMultiple,
  mdiCalendarQuestion,
  mdiAccountOutline,
  mdiAccountMultipleOutline,
  mdiPlus
} from "@mdi/js";

export default {
  data:() => {
    return {
      campaign_event_details: {},
      campaign_event_contacts: [],
      campaign_event_id: null,
      current_page: 1,
      page_size: 10,
      searchQuery: "",
      showingCampaignEventModal: false,
      description_modal: false,
      description: '',
    }
  },
  components: {
    AlertPopUp,
  },
  methods: {
    async getCampaignEvent() {
      this.loading = true;
      try {
        const item_details = await this.$api.getCampaignEvent(this.campaign_event_id);
        this.campaign_event_details = item_details;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.setTimer();
      }
    },

    async openModal(id) {
      this.campaign_event_id = id;
      this.getCampaignEvent();
      this.showingCampaignEventModal = true;
    },

    showCompleteDescription(description) {
      this.description = description
      this.description_modal = true
    },

    handleSearch: debounce(function (e) {
      this.current_page = 1;
      this.getCampaignEventContacts();
    }, 400),

    changingCampaignEventContactsPage(e) {
      this.current_page = e;
      this.getCampaignEventContacts();
    },

    changingCampaignEventContactsItemsPerPage(e) {
      this.page_size = e;
      this.getCampaignEventContacts();
    },

    async getCampaignEventContacts(id) {
      this.loadingContacts = true;
      try {
        const form = {
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
        };
        if (id) {
          const item_contacts = await this.$api.getCampaignEventContacts(id, form);
          this.campaign_event_contacts = item_contacts.results;
          this.totalEventContactListTable = item_contacts.count;
        } else {
          const item_contacts = await this.$api.getCampaignEventContacts(
            this.campaign_event_id,
            form
          );
          this.campaign_event_contacts = item_contacts.results;
          this.totalEventContactListTable = item_contacts.count;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingContacts = false;
      }
    },

    close() {
      this.current_page = 1;
      this.campaign_event_id = null;
      this.campaign_event_contacts = [];
      this.showingCampaignEventModal = false;
      clearTimeout(this.timer);
    },

    setTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.getCampaignEvent();
        this.getCampaignEventContacts();
      }, 1000 * 60);
    },
  },
  watch: {
    campaign_event_id(newValue) {
      if (newValue) {
        this.getCampaignEventContacts(this.campaign_event_id);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  setup() {
    const loading = ref(false);
    const loadingContacts = ref(false);
    const totalEventContactListTable = ref(0);

    const tableColumns = [
      { text: "NOMBRE", value: "name", align: "center", sortable: false },
      { text: "EMAIL", value: "email", align: "center", sortable: false },
    ]

    const statusColorParser = {
      0: 'info',
      1: 'success',
      2: 'error'
    }

    return {
      loading,
      loadingContacts,
      totalEventContactListTable,
      tableColumns,
      statusColorParser,
      icons: {
        mdiClose,
        mdiTagMultiple,
        mdiCalendarQuestion,
        mdiAccountOutline,
        mdiAccountMultipleOutline,
        mdiPlus
      }
    }
  }
}
</script>

<style scoped>
.font500-rgb125 {
  font-weight: 500;
  color: rgb(125, 125, 125);
}

.tool-bar {
  box-shadow: 0 0px 1px 0px rgba(94, 86, 105, 0.56) !important;
}
</style>