<template>
  <v-dialog
  v-model="showing_modal"
  width="600">
    <v-card>
      <v-card-text>
        <v-form ref="speintax_form" autocomplete="off" @submit.prevent="addText">
          <v-row class="justify-center">
            <v-col cols="8" md="8" class="ps-0">
              <v-text-field
                v-model="spintax_text"
                outlined
                :rules="[ !spintax_text_error || 'Campo requerido']"
                dense
                autocomplete="off"
                hide-details
                label="Texto"
              />
            </v-col>
            <v-col cols="2" md="2" class="d-flex justify-center">
              <v-btn color="primary" type="submit">
                Agregar
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <div class="spintax-container">
              <span v-for="(text, idx) in allTexts">
                <v-btn
                  color="error"
                  icon
                  x-small
                  @click="removeText(idx)"
                >
                  <v-icon size="15">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
                {{ text }}
              </span>
            </div>
            <v-btn color="error" outlined class="ml-9 mt-2" :disabled="!allTexts.length" @click="removeAll()">
              <v-icon size="15">
                {{ icons.mdiDeleteOutline }}
              </v-icon>
              Borrar todo
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal()">
          CERRAR
          </v-btn>
          <v-btn color="primary" :disabled="allTexts.length < 2" @click="createSpintax()">
            OK
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiDeleteOutline
} from "@mdi/js";

export default {
  data: () => {
    return {
      spintax_text: '',
      allTexts: [],
      showing_modal: false,
      spintax_text_error: false,
      message_key: null
    }
  },
  methods: {
    openModal(k) {
      this.showing_modal = true
      this.message_key = k
    },

    closeModal() {
      this.spintax_text = ''
      this.allTexts = []
      this.message_key = null
      this.spintax_text_error = false
      this.showing_modal = false
    },

    addText() {
      if(this.spintax_text) {
        this.allTexts.push(this.spintax_text)
        this.spintax_text = ''
        this.spintax_text_error = false
      } else {
        this.spintax_text_error = true
      }
    },

    removeText(idx) {
      this.allTexts.splice(idx, 1)
    },

    removeAll() {
      this.allTexts = []
    },

    createSpintax() {
      if(this.allTexts.length && this.message_key >= 0) {
        let form = {
          text: `{{${this.allTexts.join('|')}}}`,
          key: this.message_key
        }
        this.$emit('insert-spintax', form)
        this.closeModal()
      } else {
        this.closeModal()
      }
    }
  },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiDeleteOutline
      }
    }
  }
}
</script>

<style scoped>
.spintax-container {
  width: 100%;
  padding: 8px 15px;
  border: 1px solid #dbdade;
  border-radius: 7px;
  min-height: 150px;
  margin: 0 36px;
  display: flex;
  flex-direction: column;
}
</style>