<template>
  <v-dialog v-model="showingCampaignModal" fullscreen hide-overlay>
    <v-card>
      <v-card-text v-if="loading">
        Cargando...
        <v-progress-linear indeterminate color="black" class="mb-0"></v-progress-linear>
      </v-card-text>
      <v-toolbar class="tool-bar">
        <v-btn icon color="primary" @click="close()">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h5 grey lighten-2">{{
          `Campaña #${campaign_details.id} - ${campaign_details.name}`
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div>
        <v-card-subtitle class="pt-4 ml-10">
          {{
            `Creada en ${campaign_details.created_at} por el usuario ${campaign_details.created_by}`
          }}
        </v-card-subtitle>
      </div>

      <v-divider></v-divider>

      <v-card-text v-if="!loading">
        <v-row>
          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" color="success" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCheckAll }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Total de mensajes enviados</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{
                    campaign_details.process_campaign
                      ? campaign_details.process_campaign.process_ok
                      : ""
                  }}
                </span>

                <p class="text-s mb-0">Mensajes enviados por difusión</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{
                    campaign_details.process_campaign
                      ? campaign_details.process_campaign.sent_diffusion_messages
                      : ""
                  }}
                </span>

                <p class="text-xs mb-2">
                  <v-icon
                  class=""
                  color="secondary"
                  small
                  dark
                  >{{ icons.mdiCheck}}
                  </v-icon>
                  No entregados: 
                  <span v-if="campaign_details.info_status" class="text--primary text-xs font-weight-bold">
                    {{ campaign_details.info_status.undelivered.percentage }}% - {{ campaign_details.info_status.undelivered.count }} {{ campaign_details.info_status.undelivered.count === 1 ? 'mensaje' : 'mensajes' }}
                  </span>
                </p>

                <p class="text-xs mb-2">
                  <v-icon
                  class=""
                  color="secondary"
                  small
                  dark
                  >{{ icons.mdiCheckAll}}
                  </v-icon>
                  Entregados: 
                  <span v-if="campaign_details.info_status" class="text--primary text-xs font-weight-bold">
                    {{ campaign_details.info_status.delivered.percentage }}% - {{ campaign_details.info_status.delivered.count }} {{ campaign_details.info_status.delivered.count === 1 ? 'mensaje' : 'mensajes' }}
                  </span>
                </p>

                <p class="text-xs mb-2" style="white-space:nowrap">
                  <v-icon
                  class=""
                  color="accent"
                  small
                  dark
                  >{{ icons.mdiCheckAll}}
                  </v-icon>
                  Leídos: 
                  <span v-if="campaign_details.info_status" class="text--primary text-xs font-weight-bold">
                    {{ campaign_details.info_status.read.percentage }}% - {{ campaign_details.info_status.read.count }} {{ campaign_details.info_status.read.count === 1 ? 'mensaje' : 'mensajes' }} 
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                          <v-icon
                          class="ml-0"
                          color="primary"
                          dense
                          v-bind="attrs"
                          v-on="on"
                          >
                              {{ icons.mdiInformationOutline }}
                          </v-icon>
                      </template>
                      <span>Solo se visualizarán los leidos cuando el contacto o destinatario tenga habilitada esa opción.</span>
                    </v-tooltip>
                  </span>
                </p>

                <p class="text-xs mb-2">
                  <v-icon
                  class=""
                  color="accent"
                  small
                  dark
                  >{{ icons.mdiMessageOutline}}
                  </v-icon>
                  Respondidos: 
                  <span v-if="campaign_details.info_status" class="text--primary text-xs font-weight-bold">
                    {{ campaign_details.info_status.replied.percentage }}% - {{ campaign_details.info_status.replied.count }} {{ campaign_details.info_status.replied.count === 1 ? 'mensaje' : 'mensajes' }}
                  </span>
                </p>

                <div>
                  <v-tooltip
                    right
                    v-if="campaign_details && campaign_details.campaign_messages_status_file"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="createLinkClicked(campaign_details.campaign_messages_status_file)"
                      >
                        <v-icon>{{ icons.mdiFileDownloadOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span> 
                      Descargar contactos según estado del mensaje
                    </span>
                  </v-tooltip>

                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="success"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="createCampaignMessagesStatusFile(campaign_id)"
                      >
                        <v-icon>{{ icons.mdiFilePlusOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Crear archivo de contactos segun estado del mensaje
                    </span>
                  </v-tooltip>

                  <!-- <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="info"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="updateMessagesStatus(campaign_id)"
                      >
                        <v-icon>{{ icons.mdiFileRestoreOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Validar y recargar el estado de los mensajes (se volverá a generar el archivo).
                    </span>
                  </v-tooltip> -->

                </div>
                <span class="text-caption messages-status-text" :class="`${resolveMessagesStatusVariant(campaign_details.status_messages_status_file)}--text`">
                  {{ 
                    campaign_details.status_messages_status_file === 1 ? 'Se están validando los estados de mensajes y generando el archivo.' 
                    : campaign_details.status_messages_status_file === 2 ? 'Archivo disponible para descargar.'
                    : 'No se ha generado un archivo.'
                  }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" color="warning" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiProgressCheck }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Total de mensajes pendientes</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{
                    campaign_details.process_campaign
                      ? campaign_details.process_campaign.process_pending
                      : ""
                  }}
                </span>

                <p class="text-s mb-0">Pendientes por difusión</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{
                    campaign_details.process_campaign
                      ? campaign_details.process_campaign.pending_diffusion_messages
                      : ""
                  }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" color="error" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Mensajes fallidos</p>
                <span class="text--primary text-xl font-weight-bold d-flex align-center">
                  {{
                    campaign_details.process_campaign
                      ? campaign_details.process_campaign.process_error
                      : ""
                  }}
                  <v-btn
                    icon
                    color="error"
                    x-small
                    @click="
                      $refs.campaign_failed_messages_list.openFailedCauseModal(
                        campaign_details.id
                      )
                    "
                    v-if="
                      campaign_details.process_campaign &&
                      campaign_details.process_campaign.process_error > 0
                    "
                  >
                    <v-icon>{{ icons.mdiEyePlusOutline }}</v-icon>
                  </v-btn>
                  <span
                    v-if="
                      campaign_details.process_campaign &&
                      campaign_details.process_campaign.process_error > 0
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="success"
                          v-if="
                            campaign_details.status === 4 &&
                            !campaign_details.csv_message_failed
                          "
                          x-small
                          @click="createExportFailedMessages(campaign_details.id)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiFilePlusOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Crear archivo de mensajes fallidos</span>
                    </v-tooltip>
                  </span>
                </span>
                <p class="text-xs mb-0">Contactos sin Whatsapp:</p>
                <span class="text--primary text-md font-weight-bold d-flex align-center">
                  {{
                    failed_messages_count.count_dict
                      ? failed_messages_count.count_dict.count_not_whatsapp
                      : ""
                  }}
                  <v-tooltip
                    bottom
                    v-if="
                      failed_messages_count.count_dict &&
                      failed_messages_count.count_dict.count_not_whatsapp > 0
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-if="
                          campaign_details.status === 4 &&
                          campaign_details.csv_message_failed
                        "
                        x-small
                        @click="exportFailedNotWsp(campaign_details.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiFileDownloadOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Descargar archivo de contactos sin Whatsapp en formato xls</span
                    >
                  </v-tooltip>
                </span>
                <p class="text-xs mb-0">Error al verificar Whatsapp:</p>
                <span class="text--primary text-md font-weight-bold d-flex align-center">
                  {{
                    failed_messages_count.count_dict
                      ? failed_messages_count.count_dict.count_not_check
                      : ""
                  }}
                  <v-tooltip
                    bottom
                    v-if="
                      failed_messages_count.count_dict &&
                      failed_messages_count.count_dict.count_not_check > 0
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-if="
                          campaign_details.status === 4 &&
                          campaign_details.csv_message_failed
                        "
                        x-small
                        @click="exportFailedNotCheckWsp(campaign_details.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiFileDownloadOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Descargar archivo de contactos con error al verificar Whatsapp en
                      formato xls</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-if="
                          campaign_details.status === 4 && failed_resend_count.message > 0
                        "
                        x-small
                        @click="resendFailedMessages(campaign_details.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiReloadAlert }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Reenviar mensajes fallidos</span>
                  </v-tooltip>
                </span>
                <p class="text-xs mb-0">Contactos con otro error:</p>
                <span class="text--primary text-md font-weight-bold">
                  {{
                    failed_messages_count.count_dict
                      ? failed_messages_count.count_dict.count_other_errors
                      : ""
                  }}
                  <v-tooltip
                    bottom
                    v-if="
                      failed_messages_count.count_dict &&
                      failed_messages_count.count_dict.count_other_errors > 0
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-if="
                          campaign_details.status === 4 &&
                          campaign_details.csv_message_failed
                        "
                        x-small
                        @click="exportFailedOtherError(campaign_details.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiFileDownloadOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar archivo de contactos con otro error</span>
                  </v-tooltip>
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" color="primary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiHelpCircleOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Estado de la campaña</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ statusParser[campaign_details.status] }}
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text v-if="!loading">
        <v-row>
          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="accent" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCellphoneWireless }}
                </v-icon>
              </v-avatar>
              <div class="ms-3" v-if="campaign_details.channels">
                <span class="text--primary text-xl font-weight-bold">
                   Canales asociados
                </span>
                <p
                  v-if="!campaign_details.channels || !campaign_details.channels.length"
                  class="text-s mb-0"
                >
                  No hay canales asociados.
                </p>
                <div style="overflow-y: auto; max-height: 105px">
                  <div
                    v-for="(item, idx) in campaign_details.channels"
                    :key="`campaign_item_${idx}`"
                  >
                    <p class="text-s mb-0">{{ item.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="accent" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiTagMultiple }}
                </v-icon>
              </v-avatar>
              <div class="ms-3" v-if="campaign_details.tags">
                <span class="text--primary text-xl font-weight-bold">
                  Tags asociados a la campaña
                </span>
                <p
                  v-if="!campaign_details.tags || !campaign_details.tags.length"
                  class="text-s mb-0"
                >
                  No hay tags asociados.
                </p>
                <div style="overflow-y: auto; max-height: 105px">
                  <div
                    v-for="(item, idx) in campaign_details.tags"
                    :key="`campaign_item_${idx}`"
                  >
                    <p class="text-s mb-0">{{ item.name }}</p>
                  </div>
                </div>
              </div>
              <div class="ms-3" v-else-if="campaign_details.batches">
                <span class="text--primary text-xl font-weight-bold">
                  Lotes asociados a la campaña
                </span>
                <p
                  v-if="!campaign_details.batches || !campaign_details.batches.length"
                  class="text-s mb-0"
                >
                  No hay lotes asociados.
                </p>
                <div style="overflow-y: auto; max-height: 105px">
                  <div
                    v-for="(item, idx) in campaign_details.batches"
                    :key="`campaign_item_${idx}`"
                  >
                    <p class="text-s mb-0">{{ item.name }}</p>
                  </div>
                </div>
              </div>
              <div class="ms-3" v-else>
                <span class="text--primary text-xl font-weight-bold">
                  Contactos asociados a la campaña
                </span>
                <p>
                  Cargados Manualmente
                </p>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="secondary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCalendarQuestion }}
                </v-icon>
              </v-avatar>

              <div class="ms-3">
                <span class="text--primary text-xl font-weight-bold">
                  Configuración de la campaña
                </span>

                <p class="text-s mb-0">
                  Fecha de inicio: {{ campaign_details.start_date }}
                </p>
                <p class="text-s mb-0" @click="datetime_dialog = true">
                  <a>Ver días y horarios</a>
                </p>
                <span></span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="searchQuery"
              @keyup="handleSearch"
              placeholder="Buscar por usuario, canal, cliente, mensaje o teléfono"
              outlined
              hide-details
              dense
              class="user-search me-3 mb-4"
            >
            </v-text-field>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="4" md="4">
            <v-select
              class="mb-4"
              :items="[
                { name: '-', value: -1 },
                { name: 'Pendiente', value: 0 },
                { name: 'Enviado', value: 1 },
                { name: 'Fallido', value: 2 },
              ]"
              hide-details="true"
              v-model="stateFilterValue"
              label="Estado"
              item-text="name"
              item-value="value"
              @change="setFilterStatus"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="2" md="2" class="d-flex justify-center" >
            <v-btn color="primary" @click="openAttachedFiles(campaign_details)" v-if="(campaign_details.files && campaign_details.files.length) || (campaign_details.vcards && campaign_details.vcards.length)">
              <span>Ver archivos</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="campaign_messages"
        :page="current_page"
        :loading="loadingMsg"
        :server-items-length="totalMessageListTable"
        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
        @update:page="changingCampaignsMsgPage($event)"
        @update:items-per-page="changingCampaignsMsgItemsPerPage($event)"
      >
        <!-- ack_status column header -->

        <!-- user -->
        <template #[`item.user`]="{ item }">
          <div>
            {{ item.user }}
          </div>
        </template>

        <!-- channel -->
        <template #[`item.channel`]="{ item }">
          <span class="text-capitalize">{{ item.channel }}</span>
        </template>

        <!-- phone -->
        <template #[`item.phone`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{
            item.phone
          }}</span>
        </template>

        <!-- client -->
        <template #[`item.client`]="{ item }">
          <div>
            {{ item.client }}
          </div>
        </template>

        <!-- message -->
        <template #[`item.messages`]="{ item }">
            <v-btn small color="primary" outlined :disabled="!item.messages.length" @click="$refs.campaign_messages_list.openModal(item.messages, item.status)">
              <span>{{ item.messages.length ? `Ver ${item.messages.length} ${item.messages.length === 1 ? 'mensaje' : 'mensajes'}` : 'No hay mensajes' }}</span>
            </v-btn>
        </template>

        <!-- company -->
        <template #[`item.company`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{
            item.company
          }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ translateStatus(item.status) }}
          </v-chip>
        </template>

        <!-- files -->
        <template #[`item.files`]="{ item }">
          <v-btn
            v-if="item.files.length"
            class="view-files"
            elevation="2"
            x-small
            outlined
            @click="openAttachedFiles(item)"
            :plain="attached_items.length === 1 && attached_items[0] == 'disabled'"
          >
            Ver archivos
          </v-btn>

          <div v-else>-</div>
        </template>
      </v-data-table>

      <!-- Date & Time Info -->
      <div>
        <v-dialog v-model="datetime_dialog" width="250">
          <v-card class="text-center">
            <v-card-title class="primary--text text-h5 d-block">
              Días y horarios
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-for="item in campaign_details.datetime_info">
              {{ parseDays(item.day) }}: {{ item.start_hour.split(":", 2).join(":") }} a
              {{ item.end_hour.split(":", 2).join(":") }}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary " text @click="datetime_dialog = false">
                CERRAR
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <!-- Show complete Message Text -->
      <template>
        <v-dialog v-model="long_msg_modal" max-width="500">
          <v-card>
            <v-card-text>
              {{ long_msg }}
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <div class="text-center">
        <v-dialog v-model="attached_files_dialog" width="700" scrollable>

          <!-- Showing if files still downloadable -->
          <v-card v-if="attached_items.length || attached_vcards.length">
            <v-card-title class="text-h5 grey lighten-2">
              Archivos
            </v-card-title>

            <v-card-text style="max-height: 300px">
              <v-list-item
                v-for="(item, idx) in attached_items"
                :key="`attached_item_${idx}`"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn icon elevation="2" @click="createLinkClicked(item.url)">
                    <v-icon v-text="icons.mdiDownload"></v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item
                v-for="(vcard, idx) in attached_vcards"
                :key="`attached_vcard_${idx}`"
              >
                <v-list-item-content class="align-self-start">
                  <v-list-item-title v-text="`VCard ${vcard.type === 'static' ? 'estática' : vcard.type === 'dynamic' ? 'dinámica' : ''}`"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="align-start" style="width: 30%">
                  <v-list-item-action-text 
                  v-for="(val, key, index) in vcard.vcard" 
                  :key="index"
                  class="text-wrap"
                  >
                    <strong>{{ contactVariableParser(key) }}:</strong> {{ val ? val : ' - ' }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="attached_files_dialog = false">
                CERRAR
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <FailedMessagesList ref="campaign_failed_messages_list" />

      <CampaignMessagesList ref="campaign_messages_list" />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()"> CERRAR </v-btn>
      </v-card-actions>
      <alert-pop-up ref="alert_pop_up" />
    </v-card>
  </v-dialog>
</template>

<script>
const debounce = require("debounce");
import { ref } from "@vue/composition-api";
import ProcessedMessagesDetails from "@/components/Campaigns/ProcessedMessagesDetails";
import FailedMessagesList from "@/components/Campaigns/CampaignFailedMessagesList";
import CampaignMessagesList from "@/components/Campaigns/CampaignMessagesList";
import AlertPopUp from "@/components/AlertPopUp.vue";

import {
  mdiCheckAll,
  mdiCheck,
  mdiProgressCheck,
  mdiClose,
  mdiHelpCircleOutline,
  mdiTagMultiple,
  mdiCalendarQuestion,
  mdiWhatsapp,
  mdiReloadAlert,
  mdiSendOutline,
  mdiDownload,
  mdiEyePlusOutline,
  mdiPlus,
  mdiFileDownloadOutline,
  mdiFilePlusOutline,
  mdiCellphoneWireless,
  mdiInformationOutline,
  mdiMessageOutline,
  mdiFileRestoreOutline
} from "@mdi/js";

export default {
  data: () => {
    return {
      campaign_details: {},
      campaign_messages: [],
      failed_messages_count: {},
      failed_resend_count: null,
      datetime_table: {},
      campaign_id: null,
      datetime_dialog: false,
      attached_files_dialog: false,
      attached_items: [],
      attached_vcards: [],
      current_page: 1,
      page_size: 10,
      searchQuery: "",
      stateFilterValue: -1,
      long_msg: "",
      long_msg_modal: false,
      timer: null,
    };
  },
  components: {
    ProcessedMessagesDetails,
    FailedMessagesList,
    CampaignMessagesList,
    AlertPopUp,
  },
  methods: {
    async getCampaigns() {
      this.loading = true;
      try {
        const item_details = await this.$api.getCampaign(this.campaign_id);
        const failed_messages_count = await this.$api.failedMessagesCount(
          this.campaign_id
        );
        const failed_resend_count = await this.$api.toResendCount(this.campaign_id);
        this.failed_resend_count = failed_resend_count;
        this.failed_messages_count = failed_messages_count;
        this.campaign_details = item_details;

      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.setTimer();
      }
    },
    async openModal(id) {
      this.campaign_id = id;
      this.getCampaigns();
      this.showingCampaignModal = true;
    },
    parseDays(day) {
      const weekDays = [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ];
      return weekDays[day];
    },
    contactVariableParser(item) {
      if(item === 'name') return 'nombre'
      if(item === 'phone') return 'teléfono'
      if(item === 'address') return 'dirección'
      if(item === 'address_type') return 'tipo de dirección'
      if(item === 'note') return 'nota'
      if(item === 'categories') return 'categorías'
      return item
    },
    handleSearch: debounce(function (e) {
      this.current_page = 1;
      this.getCampaignMessages();
    }, 400),

    setFilterStatus() {
      this.current_page = 1;
      this.getCampaignMessages();
    },

    changingCampaignsMsgPage(e) {
      this.current_page = e;
      this.getCampaignMessages();
    },

    changingCampaignsMsgItemsPerPage(e) {
      this.page_size = e;
      this.getCampaignMessages();
    },
    async getCampaignMessages(id) {
      this.loadingMsg = true;
      try {
        const form = {
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
          status: this.stateFilterValue,
        };
        if (id) {
          const item_messages = await this.$api.getCampaignMessageList(id, form);
          this.campaign_messages = item_messages.results;
          this.totalMessageListTable = item_messages.count;
        } else {
          const item_messages = await this.$api.getCampaignMessageList(
            this.campaign_id,
            form
          );
          this.campaign_messages = item_messages.results;
          this.totalMessageListTable = item_messages.count;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingMsg = false;
      }
    },
    parseStartAndEndHours(value) {
      return value.split(":", 2).join(":");
    },
    translateStatus(value) {
      const translation = {
        PENDING: "PENDIENTE",
        SENDED: "ENVIADO",
        FAILED: "FALLIDO",
      };
      return translation[value] ?? "error";
    },
    openAttachedFiles(item) {
      this.attached_items = item.files ?? [];
      this.attached_vcards = item.vcards ?? []
      this.attached_files_dialog = true;
    },
    async downloadAttachedFile(file_id) {
      const url = await this.$api.downloadAttachedFile(file_id);
      this.createLinkClicked(url);
    },
    createLinkClicked(url) {
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      // console.log(url)
      a.click();
    },
    close() {
      this.current_page = 1;
      this.campaign_id = null;
      this.campaign_details = {};
      this.campaign_messages = [];
      this.showingCampaignModal = false;
      clearTimeout(this.timer);
    },
    showCompleteMsg(message) {
      this.long_msg = message;
      this.long_msg_modal = true;
    },

    downloadFailedMessage(data) {
      const blob = new Blob([data], { type: "text/html;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `MensajesFallidos-${this.campaign_details.name}.xls`);
      link.click();
    },

    async createExportFailedMessages(id) {
      const response = await this.$api.createExportFailedMessages(id);
      if (response.status === 200) {
        this.$refs.alert_pop_up.showPopUp({
          message: response.data.message,
          type: "success",
        });
      }
      this.getCampaigns();
    },

    async exportFailedNotWsp(id) {
      try {
        const data = {
          id: id,
          failed_not_whatsapp: true,
        };
        const response = await this.$api.exportFailedMessages(data);
        this.downloadFailedMessage(response.data);
      } catch (e) {
        if (e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({
            message: e?.response?.data?.message,
            type: "error",
          });
        }
      }
    },

    async exportFailedNotCheckWsp(id) {
      try {
        const data = {
          id: id,
          failed_not_check_whatsapp: true,
        };
        const response = await this.$api.exportFailedMessages(data);
        this.downloadFailedMessage(response.data);
      } catch (e) {
        if (e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({
            message: e?.response?.data?.message,
            type: "error",
          });
        }
      }
    },

    async exportFailedOtherError(id) {
      try {
        const data = {
          id: id,
        };
        const response = await this.$api.exportFailedMessages(data);
        this.downloadFailedMessage(response.data);
      } catch (e) {
        if (e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({
            message: e?.response?.data?.message,
            type: "error",
          });
        }
      }
    },

    async resendFailedMessages(id) {
      const response = await this.$api.resendFailedMessages(id);
      if (response.status === 200) {
        this.$refs.alert_pop_up.showPopUp({
          message: response.data.message,
          type: "success",
        });
      }
      this.getCampaigns();
    },

    setTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        // console.log(this.campaign_id)
        this.getCampaigns();
        this.getCampaignMessages();
      }, 1000 * 60);
    },

    async createCampaignMessagesStatusFile(id) {
      const response = await this.$api.createCampaignMessagesStatusFile(id);
      if (response.status === 200) {
        this.$refs.alert_pop_up.showPopUp({
          message: response.data.message,
          type: "success",
        });
      }
      this.getCampaigns()
    },

    async updateMessagesStatus(id) {
      const response = await this.$api.updateMessagesStatus(id);
      if (response.status === 200) {
        this.$refs.alert_pop_up.showPopUp({
          message: response.data.message,
          type: "info",
        });
      }
      this.getCampaigns()
    }
  },
  computed: {
    statusParser() {
      const parser = {
        0: "Enviando",
        1: "Enviando",
        2: "Enviando",
        3: "Detenido",
        4: "Finalizado",
        5: "En creación",
        6: "Archivada"
      };
      return parser;
    },
  },
  watch: {
    campaign_id(newValue) {
      if (newValue) {
        this.getCampaignMessages(this.campaign_id);
      }
    },
  },
  beforeDestroy() {
    // console.log(this.campaign_id)
    clearTimeout(this.timer);
  },
  setup() {
    const showingCampaignModal = ref(false);
    const loading = ref(false);
    const loadingMsg = ref(false);

    const totalMessageListTable = ref(0);

    const resolveMessagesStatusVariant = status => {
      if (status === 0) return 'primary'
      if (status === 1) return 'info'
      if (status === 2) return 'success'

      return 'primary'
    }

    const tableColumns = [
      // {
      //   text: "STATUS",
      //   value: "ack_status",
      //   align: "center",
      //   sortable: false,
      //   width: "90px",
      // },
      { text: "USUARIO", value: "user", align: "center", sortable: false },
      { text: "CANAL", value: "channel", align: "center", sortable: false },
      { text: "TELÉFONO", value: "phone", align: "center", sortable: false },
      { text: "CLIENTE", value: "client", align: "center", sortable: false },
      { text: "MENSAJES", value: "messages", align: "center", sortable: false },
      // { text: "FECHA", value: "date", align: "center", sortable: false },
      // { text: "HORA", value: "time", align: "center", sortable: false },
      { text: "ESTADO", value: "status", align: "center", sortable: false },
      // { text: "ARCHIVOS", value: "files", align: "center", sortable: false },
    ];

    const resolveUserStatusVariant = (status) => {
      if (status === "FAILED") return "error";
      if (status === "SENDED") return "success";
      if (status === "PENDING") return "primary";

      return "primary";
    };

    return {
      showingCampaignModal,
      loading,
      tableColumns,
      totalMessageListTable,
      loadingMsg,
      resolveMessagesStatusVariant,
      resolveUserStatusVariant,
      icons: {
        mdiCheckAll,
        mdiCheck,
        mdiProgressCheck,
        mdiClose,
        mdiHelpCircleOutline,
        mdiTagMultiple,
        mdiCalendarQuestion,
        mdiWhatsapp,
        mdiReloadAlert,
        mdiSendOutline,
        mdiDownload,
        mdiEyePlusOutline,
        mdiPlus,
        mdiFileDownloadOutline,
        mdiFilePlusOutline,
        mdiCellphoneWireless,
        mdiInformationOutline,
        mdiMessageOutline,
        mdiFileRestoreOutline
      },
    };
  },
};
</script>

<style scoped>
.status-div {
  display: flex;
  align-items: center;
}

.tool-bar {
  box-shadow: 0 0px 1px 0px rgba(94, 86, 105, 0.56) !important;
}

.messages-status-text{
  overflow:auto;
  white-space: nowrap;
}
</style>
