import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VRow,[_c(VCardTitle,{staticClass:"pr-0"},[_vm._v("Campañas")]),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-0",attrs:{"color":"primary","dense":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v("Las campañas pueden tardar un tiempo en verse después de crearse.")])])],1),_c(VTabs,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTab,{attrs:{"value":0}},[_vm._v("Campañas")]),(_vm.has_google_account)?_c(VTab,{attrs:{"value":1}},[_vm._v("Eventos de campaña")]):_vm._e()],1),_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTabItem,{attrs:{"value":0}},[_c('CampaignsList')],1),_c(VTabItem,{attrs:{"value":1}},[_c('CampaignEventsList')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }