<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          @keyup="handleSearch"
          placeholder="Buscar por nombre de la campaña"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <v-select
          style="width: 0px"
          class="mb-4 mr-2"
          :items="[
            { name: '-', value: -1 },
            { name: 'Procesando', value: 1 },
            { name: 'Pendiente', value: 0 },
            { name: 'Pausado', value: 2 },
            { name: 'Detenido', value: 3 },
            { name: 'Finalizado', value: 4 },
            { name: 'Enviando', value: 5},
            { name: 'Archivado', value: 6}
          ]"
          hide-details="true"
          v-model="statusFilterValue"
          label="Estado"
          item-text="name"
          item-value="value"
          @change="setFilter"
          outlined
          dense
        ></v-select>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            v-if="(getUserRole === 0 || 1) && getSelectedCompany !== 'all'"
            @click="$refs.campaign_form_modal.openModal()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Crear Campaña</span>
          </v-btn>
        </div>
      </v-card-text>
      <!--List-->
      <v-data-table
        :headers="tableColumns"
        :items="campaignListTable"
        :server-items-length="totalCampaignListTable"
        :page="current_page"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
        @update:page="changingCampaignsPage($event)"
        @update:items-per-page="changingCampaignsItemsPerPage($event)"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Campañas</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

        <!--Date-->
        <template #[`item.created_at`]="{ item }">
          <span class="date-container">{{ item.created_at }}</span>
        </template>

        <!--Name-->
        <template #[`item.name`]="{ item }">
          <v-btn
            class="no-uppercase campaign-name-container pa-1 mr-2"
            text
            color="primary"
            min-width="0"
            max-height="28px"
            @click="$refs.campaign_details_modal.openModal(item.id)"
          >
            {{ item.name }}
          </v-btn>
          <div class="d-flex flex-column">
            <div class="d-flex align-end mb-1" v-for="(channel, idx) in item.channels">
              <span class="text-left" :class="!channel.status ? 'error--text' : ''">{{ channel.name }}: {{ channel.messages }}</span>
              <v-tooltip v-if="channel.in_breakdown" right color="primary" open-delay="300">
              <template v-slot:activator="{ on, attrs }">
                <button
                v-on="on"
                v-bind="attrs"
                class="ml-2"
                
                >
                  <v-icon
                  :color="'primary'"
                  style="width: 20px; height: 20px;"
                  >
                    {{icons.mdiSleep}}
                  </v-icon>
                </button>
              </template>
              <span>Descansando desde {{ (channel.campaign_block && channel.campaign_block.start_break_date) ? channel.campaign_block.start_break_date.replace('T', ' ').replace('Z', '') : ''}} hasta {{ (channel.campaign_block && channel.campaign_block.end_break_date) ? channel.campaign_block.end_break_date.replace('T', ' ').replace('Z', '') : '' }}</span>
            </v-tooltip>
            </div>
          </div>
        </template>
        <!--Status-->
        <template #[`item.status`]="{ item }">
          <v-btn
            class="no-uppercase status-container pa-2"
            depressed
            :color="statusColorParser(item.status)"
            max-height="26px"
            min-width="0"
            v-if="
              (item.percentage_progress_creation === 0 && item.status !== 0) ||
              item.percentage_progress_creation === 100
            "
          >
            <span>{{ statusParser[item.status] }}</span>
          </v-btn>
          <div
            v-else
            class="rounded-lg relative"
            style="
              width: 100%;
              background-color: #bdbdbd;
              height: 26px;
              position: relative;
            "
          >
            <div
              class="rounded-lg"
              :style="{
                width: item.percentage_progress_creation + '%',
                'background-color':
                  item.percentage_progress_creation < 25
                    ? '#F44336'
                    : item.percentage_progress_creation >= 25 &&
                      item.percentage_progress_creation <= 50
                    ? '#FF9800'
                    : item.percentage_progress_creation > 50 &&
                      item.percentage_progress_creation <= 75
                    ? '#FFEB3B'
                    : '#4CAF50',
                height: '26px',
              }"
            ></div>
            <span
              style="
                position: absolute;
                top: 2px;
                bottom: 0;
                left: 0;
                right: 0;
                color: white;
              "
              >{{ item.percentage_progress_creation }}%</span
            >
          </div>
        </template>

        <!--Messages-->
        <template #[`item.messages`]="{ item }">
          <div>Enviados totales: {{ item.sent_messages }}</div>
          <div>Enviados difusión: {{ item.sent_diffusion_messages }}</div>
          <div>Pendientes totales: {{ item.pending_messages }}</div>
          <div>Pendientes difusión: {{ item.pending_diffusion_messages }}</div>
        </template>

        <!--Actions-->
        <template #[`item.actions`]="{ item }">
          <v-item-group class="v-btn-group">
            <v-btn
              class="grouped-btn px-3"
              depressed
              color="primary"
              min-width="0"
              v-if="item.status === 0 || item.status === 1 || item.status == 2"
              @click="openPauseModal(item.id)"
            >
              <v-icon>
                {{ icons.mdiPause }}
              </v-icon>
            </v-btn>
            <v-btn
              class="grouped-btn px-3"
              depressed
              color="success"
              min-width="0"
              v-if="item.status === 3"
              @click="openPlayModal(item.id)"
            >
              <v-icon>
                {{ icons.mdiPlay }}
              </v-icon>
            </v-btn>
            <v-btn
              class="grouped-btn px-3"
              depressed
              color="error"
              min-width="0"
              @click="$refs.delete_campaign_modal.open(item)"
            >
              <v-icon>
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              class="grouped-btn px-3"
              depressed
              :color="pendingColor"
              min-width="0"
              @click="$refs.campaign_form_modal.openModal(item)"
            >
              <v-icon color="white">
                {{ icons.mdiContentCopy }}
              </v-icon>
            </v-btn>
          </v-item-group>
        </template>

        <!-- Company -->
        <template #[`item.company`]="{ item }">
          <span class="date-container">{{ item.company }}</span>
        </template>

      </v-data-table>
      <CampaignDetailsModal ref="campaign_details_modal" />

      <CampaignFormModal
        ref="campaign_form_modal"
        @reload-campaign-list="getCampaigns()"
        @show-created-modal="openCreatedCampaignModal"
      />

      <DeleteCampaignModal
        ref="delete_campaign_modal"
        @reload-campaign-list="getCampaigns()"
        @show-deleted-modal="openDeletedCampaignModal"
      />

      <!--Created campaign modal-->
      <template>
        <v-dialog v-model="showCreatedCampaign" width="550px">
          <v-card>
            <v-card-text>
              <h3 class="d-flex justify-center created-msg">
                {{ createdCampaignMessage }}
              </h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeCreatedCampaignModal()">
                CERRAR
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <!--Pop up pause campaign-->
      <template>
        <v-dialog v-model="showingPauseCampaignModal" width="auto" persistent>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{
                campaignToPauseInfo && campaignToPauseInfo.processed_messages > 0
                  ? "La campaña ya ha iniciado y algunos mensajes fueron enviados. Está seguro de pausarla?"
                  : "Está seguro de querer pausar la campaña?"
              }}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="closePauseModal()"> CANCELAR </v-btn>
              <v-btn color="error" @click="pauseCampaign(changeCampaignStatusId)">
                Pausar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <!--Pop up play campaign-->
      <template>
        <v-dialog v-model="showingPlayCampaignModal" width="auto" persistent>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              La campaña se iniciará en los horarios establecidos. Está seguro de
              iniciarla?
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="closePlayModal()"> CANCELAR </v-btn>
              <v-btn color="success" @click="playCampaign(changeCampaignStatusId)">
                Iniciar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <!--Deleted campaign modal-->
      <template>
        <v-dialog v-model="showDeletedCampaign" width="550px">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Campaña eliminada correctamente.
            </v-card-title>
            <v-card-subtitle class="pt-3">
              Ésta acción puede tomarse un tiempo, por lo que es posible que tarde en
              reflejarse en la lista de campañas.
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDeletedCampaignModal()">
                CERRAR
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-card>
  </div>
</template>

<script>
const debounce = require("debounce");
import { ref } from "@vue/composition-api";
import CampaignDetailsModal from "@/components/Campaigns/CampaignDetailsModal";
import CampaignFormModal from "@/components/Campaigns/CampaignFormModal";
import DeleteCampaignModal from "@/components/Campaigns/DeleteCampaignModal";
import {
  mdiAccountMultipleOutline,
  mdiPause,
  mdiPlay,
  mdiDeleteOutline,
  mdiPlus,
  mdiContentCopy,
  mdiSleep
} from "@mdi/js";

export default {
  components: {
    CampaignDetailsModal,
    CampaignFormModal,
    DeleteCampaignModal,
  },
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      stopedColor: "rgb(255, 76, 81)",
      pendingColor: "rgb(24, 103, 192)",
      creatingColor: "rgb(146, 149, 143)",
      completedColor: "rgb(86, 202, 0)",
      inProgressColor: "rgb(145, 85, 253)",
      pausedColor: "rgb(255, 180, 0)",
      showCreatedCampaign: false,
      showDeletedCampaign: false,
      createdCampaignMessage: null,
      showingPauseCampaignModal: false,
      changeCampaignStatusId: null,
      showingPlayCampaignModal: false,
      campaignToPauseInfo: null,
      statusFilterValue: -1,
      timer: null,
    };
  },
  mounted() {
    if (this.getSelectedCompany) {
      this.getCampaigns();
    }
  },
  methods: {
    async pauseCampaign(id) {
      const data = {
        status: 3,
      };
      const response = await this.$api.updateCampaignStatus(id, data);
      this.getCampaigns();
      this.closePauseModal();
    },

    handleSearch: debounce(function (e) {
      this.current_page = 1;
      this.getCampaigns();
    }, 400),

    changingCampaignsPage(e) {
      this.current_page = e;
      this.getCampaigns();
    },

    changingCampaignsItemsPerPage(e) {
      this.page_size = e;
      this.getCampaigns();
    },
    async openPauseModal(id) {
      this.changeCampaignStatusId = id;
      try {
        const item = await this.$api.getCampaign(id);
        this.campaignToPauseInfo = item;
        this.showingPauseCampaignModal = true;
      } catch (e) {
        console.log(e);
      }
    },

    resetTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getCampaigns();
      }, 1000 * 45);
    },

    openPlayModal(id) {
      this.changeCampaignStatusId = id;
      this.showingPlayCampaignModal = true;
    },

    closePauseModal() {
      this.changeCampaignStatusId = null;
      this.showingPauseCampaignModal = false;
      this.campaignToPauseInfo = null;
    },
    closePlayModal() {
      this.changeCampaignStatusId = null;
      this.showingPlayCampaignModal = false;
    },

    openCreatedCampaignModal(message) {
      this.createdCampaignMessage = message;
      this.showCreatedCampaign = true;
    },

    closeCreatedCampaignModal() {
      this.createdCampaignMessage = null;
      this.showCreatedCampaign = false;
    },

    openDeletedCampaignModal() {
      this.showDeletedCampaign = true;
    },

    closeDeletedCampaignModal() {
      this.showDeletedCampaign = false;
    },

    setFilter() {
      this.current_page = 1;
      this.getCampaigns();
    },

    statusColorParser(status) {
      if (status === 0) return this.inProgressColor;
      if (status === 1) return this.inProgressColor;
      if (status === 2) return this.inProgressColor;
      if (status === 3) return this.stopedColor;
      if (status === 4) return this.completedColor;
      if (status === 5) return this.creatingColor;
      if (status === 6) return this.pendingColor;
    },

    async getCampaigns() {
      this.loading = true;
      try {
        const query = {
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
          company_id: this.getSelectedCompany,
        };
        const filters = {
          status: this.statusFilterValue,
        };

        const response = await this.$api.getCampaigns(query, filters);
        this.campaignListTable = response.results;
        this.totalCampaignListTable = response.count;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.resetTimer();
      }
    },

    async playCampaign(id) {
      const data = {
        status: 0,
      };
      const response = await this.$api.updateCampaignStatus(id, data);
      this.getCampaigns();
      this.closePlayModal();
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  computed: {
    getUserRole() {
      return this.$store.getters["session/getUserRole"]();
    },
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
    statusParser() {
      const parser = {
        0: "Enviando",
        1: "Enviando",
        2: "Enviando",
        3: "Detenido",
        4: "Finalizado",
        5: "En creación",
        6: "Archivada",
      };
      return parser;
    },
    tableColumns() {
      let tableColumns = [
        { text: "Nombre", value: "name", align: "left", sortable: false },
        { text: "Fecha", value: "created_at", align: "center", sortable: false },
        { text: "Estado", value: "status", align: "center", sortable: false },
        { text: "Mensajes", value: "messages", align: "start", sortable: false },
        { text: "Acciones", value: "actions", align: "center", sortable: false },
      ];

      if(this.getSelectedCompany === 'all') {
        tableColumns.push({ text: "Empresa", value: "company", align: "center", sortable: false })
      }

      return tableColumns
    }
  },

  watch: {
    getSelectedCompany(newValue) {
      if (newValue) {
        this.current_page = 1;
        this.page_size = 10;
        this.getCampaigns();
      }
    },
  },
  setup() {
    const totalCampaignListTable = ref(1);
    const campaignListTable = ref([]);

    const loading = ref(false);
    const searchQuery = ref("");

    return {
      totalCampaignListTable,
      campaignListTable,
      loading,
      searchQuery,
      icons: {
        mdiAccountMultipleOutline,
        mdiPause,
        mdiPlay,
        mdiDeleteOutline,
        mdiPlus,
        mdiContentCopy,
        mdiSleep
      },
    };
  },
};
</script>

<style>
.no-uppercase {
  text-transform: unset !important;
}

.campaign-name-container span {
  font-weight: 600;
  font-size: 15px;
}

.campaign-participants-container {
  min-width: 0 !important;
  background-color: rgba(137, 43, 226, 0.2);
  color: rgba(137, 43, 226, 0.9);
  border: 1.5px solid rgb(130, 15, 236);
}

.campaign-participants-container span {
  font-size: 14px;
  color: rgba(137, 43, 226, 0.9);
}

.date-container {
  font-weight: 500;
  color: rgb(125, 125, 125);
}

.status-container {
  color: white !important;
  font-size: 13px;
}

.grouped-btn {
  border: none;
  border-radius: 0px;
}

.grouped-btn:first-child {
  border-radius: 10px 0 0 10px;
}

.grouped-btn:last-child {
  border-radius: 0 10px 10px 0;
}

.created-msg {
  overflow-wrap: break-word;
  font-weight: 500;
}
</style>
