<template>
    <v-dialog
        v-model="showingDeleteCampaignModal"
        width="auto"
    >
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                ¿Estás seguro de eliminar la campaña '{{ campaign.name }}'?
            </v-card-title>
            <v-card-subtitle class="pt-3">Tenga en cuenta que si la campaña ya comenzó, algunos mensajes ya podrian haber sido enviados.</v-card-subtitle>

            <v-divider></v-divider>

            <v-card-actions>
                <span 
                    class="error--text error-msg"
                    v-if="error_msg"
                    >{{ error_msg }}</span>
                <v-spacer></v-spacer>

                <v-btn 
                color="primary" 
                text 
                @click="close()"
                >
                    CANCELAR
                </v-btn>         
                <v-btn
                color="error"
                @click="deleteCampaign()"
                >
                    Eliminar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    data: () => {
        return {
            showingDeleteCampaignModal: false,
            campaign: {},
            error_msg: null
        }
    },

    methods: {
        open(campaign) {
            this.showingDeleteCampaignModal = true
            this.campaign = campaign
        },

        close() {
            this.showingDeleteCampaignModal = false
            this.error_msg = null
        },

        async deleteCampaign() {
            try {
                const response = await this.$api.deleteCampaign(this.campaign.id)
                this.$emit('reload-campaign-list')
                this.$emit('show-deleted-modal')
                this.close()
            } catch(e) {
                console.log(e)
                if (e?.response?.status === 400) {
                    console.log(e.response.data)
                    this.error_msg = e?.response?.data?.message
                }
            } 
        }
    }
}
</script>

<style>
.error-msg {
    inline-size: 300px;
    overflow-wrap: break-word;
}
</style>