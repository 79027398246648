<template>
    <div>
        <v-card>
            <v-row>
                <v-card-title class="pr-0">Campañas</v-card-title>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                        class="ml-0"
                        color="primary"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        >
                            {{ icons.mdiInformationOutline }}
                        </v-icon>
                    </template>
                    <span>Las campañas pueden tardar un tiempo en verse después de crearse.</span>
                </v-tooltip>

            </v-row>
            <v-tabs v-model="currentTab">
                <v-tab :value="0">Campañas</v-tab>
                <v-tab v-if="has_google_account" :value="1">Eventos de campaña</v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentTab">
                <v-tab-item :value="0">
                    <CampaignsList></CampaignsList>
                </v-tab-item>

                <v-tab-item :value="1">
                    <CampaignEventsList></CampaignEventsList>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import CampaignsList from '@/components/Campaigns/CampaignsList';
import CampaignEventsList from '@/components/Campaigns/CampaignEventsList'
import {  
        mdiInformationOutline,
    } from '@mdi/js';

export default {
    data:() => {
        return {
            currentTab: null,
            has_google_account: false,
        }
    },
    components: {
        CampaignsList,
        CampaignEventsList
    },
    methods: {
        async checkIfCompanyHasGoogle() {
            this.has_google_account = false
            try {
                const response = await this.$api.checkIfCompanyHasGoogle(this.getSelectedCompany)
                // console.log(response.status)
                this.has_google_account = response.data.has_google_account
            } catch (e) {
                console.log(e);
                // console.log(e.response.status);
            }
        },
    },
    computed: {
        getSelectedCompany() {
            return this.$store.getters["session/getSelectedCompany"]();
        },
    },
    mounted() {
        if(this.getSelectedCompany && this.getSelectedCompany !== 'all') {
            this.checkIfCompanyHasGoogle()
        }
    },
    watch: {
        getSelectedCompany(newValue) {
            if (newValue !== 'all') {
                this.checkIfCompanyHasGoogle()
            }
            if(newValue === 'all') {
                this.has_google_account = true
            }
        }
    },
    setup() {
        return {
            icons: {
                mdiInformationOutline
            }
        }
    }
}

</script>