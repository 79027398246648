<template>
    <v-dialog v-model="showingModal" width="40%">
        <v-card>
            <v-card-text v-if="loading">
                Cargando...
                <v-progress-linear
                indeterminate
                color="black"
                class="mb-0"
                ></v-progress-linear>
            </v-card-text>

            <div v-show="!loading">
                <v-card-title class="pb-1">
                    {{index === 0 ? 'Procesados con éxito' : index === 1 ? 'Procesados pendientes' : 'Procesados con error'}}
                </v-card-title>
                <v-card-subtitle v-if="!processed_data.length" class="pt-8 no-msgs-span">
                    {{index === 0 ? 'No hay mensajes procesados con éxito' : index === 1 ? 'No hay mensajes procesados pendientes' : 'No hay mensajes procesados con error'}}
                </v-card-subtitle>
                <v-list v-show="!loading">
                    <v-list-item
                    v-for="(item, k) in processed_data"
                    :key="k"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                Contacto: {{item.name}}
                            </v-list-item-title>
                            <v-list-item-subtitle class="d-flex flex-column">
                                <span>Número: {{item.phone}}</span>
                                <span v-if="item.status">Estado: {{parseValue(item.status)}}</span>
                                <span v-if="item.send_at">Fecha de envío: {{item.send_at}}</span>
                                <span v-if="item.cause">Causa del error: {{item.cause}}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>
            <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="primary"
                    text
                    @click="close()"
                    >
                    Cerrar</v-btn>
                </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';
export default {
    data: () => {
        return {
            processed_data: {},
            index: null
        }
    },
    methods: {
        async open(index, id) {
            this.loading = true
            this.index = index
            try {
                const item = index === 0 
                                ? await this.$api.getCampaignSendedMessages(id)
                                : index === 1
                                    ? await this.$api.getCampaignPendingMessages(id)
                                    : await this.$api.getCampaignFailedMessages(id)

                this.processed_data = item
                this.loading = false
                this.showingModal = true
            } catch(e) {
                console.log(e)
            }
        },

        close() {
            this.processed_data = {}
            this.index = null
            this.showingModal = false
        },

        parseValue(value) {
            if (value === 'read') return 'Leído'
            if (value === 'send') return 'Enviado'
            if (value === 'reveived') return 'Recibido'
            if (value === 'notcheck') return 'Sin comprobar'

            return value
        }
    },
    setup() {
        const showingModal = ref(false)
        const loading = ref(false)

        return {
            showingModal,
            loading
        }
    }
}
</script>

<style>
.no-msgs-span {
    font-size: 16px !important;
}
</style>