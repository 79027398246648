import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Buscar por nombre del evento de campaña","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c(VSelect,{staticClass:"mb-4 mr-2",staticStyle:{"width":"0px"},attrs:{"items":[
          { name: '-', value: -1 },
          { name: 'Pendiente', value: 0 },
          { name: 'Creado', value: 1 },
          { name: 'Fallido', value: 2 } ],"hide-details":"true","label":"Estado","item-text":"name","item-value":"value","outlined":"","dense":""},on:{"change":_vm.setFilter},model:{value:(_vm.statusFilterValue),callback:function ($$v) {_vm.statusFilterValue=$$v},expression:"statusFilterValue"}}),_c('div',{staticClass:"d-flex align-center flex-wrap"},[((_vm.getUserRole === 0 || 1) && _vm.getSelectedCompany !== 'all')?_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.campaign_form_modal.openModal()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Crear Campaña")])],1):_vm._e()],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.campaignEventListTable,"server-items-length":_vm.totalCampaignEventListTable,"page":_vm.current_page,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:page":function($event){return _vm.changingCampaignEventsPage($event)},"update:items-per-page":function($event){return _vm.changingCampaignEventsItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No hay "),_c('strong',[_vm._v("Eventos de Campaña")]),_vm._v(" disponibles para esta búsqueda ")])],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.created_at))])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase campaign-name-container pa-1 mr-2",attrs:{"text":"","color":"primary","min-width":"0","max-height":"28px"},on:{"click":function($event){return _vm.$refs.campaign_event_details_modal.openModal(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase status-container pa-2",attrs:{"depressed":"","color":_vm.statusColorParser(item.status.id),"max-height":"26px","min-width":"0"}},[_c('span',[_vm._v(_vm._s(item.status.label))])])]}},{key:"item.total_contacts",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.total_contacts))])]}},{key:"item.company_name",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.company_name))])]}}],null,true)}),[_c(VDialog,{attrs:{"width":"550px"},model:{value:(_vm.showCreatedCampaign),callback:function ($$v) {_vm.showCreatedCampaign=$$v},expression:"showCreatedCampaign"}},[_c(VCard,[_c(VCardText,[_c('h3',{staticClass:"d-flex justify-center created-msg"},[_vm._v(" "+_vm._s(_vm.createdCampaignMessage)+" ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeCreatedCampaignModal()}}},[_vm._v(" CERRAR ")]),_c(VSpacer)],1)],1)],1)],_c('CampaignFormModal',{ref:"campaign_form_modal",on:{"reload-campaign-list":function($event){return _vm.getCampaignEvents()},"show-created-modal":_vm.openCreatedCampaignModal}}),_c('CampaignEventDetailsModal',{ref:"campaign_event_details_modal"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }