<template>
  <v-dialog v-model="failedMessagesCauseModal">
    <v-card>
      <v-data-table
      :headers="tableColumns"
      :items="failed_messages_cause"
      :page="current_page"
      :loading="loading"
      :server-items-length="totalListTable"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      @pagination="changingMsgPage($event)"
      >
        <template #[`item.contact`]="{item}">
          <div>
            {{ item.contact }}
          </div>
        </template>

        <template #[`item.error`]="{item}">
          <div>
            {{ item.error }}
          </div>
        </template>

        <template #[`item.phone`]="{item}">
          <div>
            {{ item.phone }}
          </div>
        </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close()">
        CERRAR
      </v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  data: () => {
    return {
      failed_messages_cause: [],
      current_page: 1,
      failedMessagesCauseModal: false,
      page_size: 10,
      campaign_id: null,
    }
  },
  methods: {
    async getFailedMessagesCause() {
      try {
        const data = {
          page_size: this.page_size,
          page: this.current_page,
        }
        const item = await this.$api.getFailedMessagesCause(this.campaign_id, data)
        this.failed_messages_cause = item.data.results
      
        this.loading = false
        this.totalListTable = item.data.count
        this.failedMessagesCauseModal = true
      } catch (e) {
        console.log(e);
      }
    },
    async openFailedCauseModal(id) {
        // console.log(item)
      this.loading = true
      this.campaign_id = id
      this.getFailedMessagesCause()
    },
    changingMsgPage(e) {
      if (e.pageStop !== 0) {
        // console.log(e)
        this.current_page = e.page
        this.page_size = e.itemsPerPage
        this.getFailedMessagesCause()
    }
  },
  close(){
    this.campaign_id = null
    this.current_page = 1
    this.page_size = 10
    this.failed_messages_cause = []
    this.totalListTable = 0
    this.failedMessagesCauseModal = false
  }
  },
  setup() {
      const loading = ref(false)
      const totalListTable = ref(0)

      const tableColumns = [
        { text: 'Contacto', value: 'contact', align: 'center', sortable: false},
        { text: 'Error', value: 'error', align: 'center', sortable: false },
        { text: 'Teléfono', value: 'phone', align: 'center', sortable: false },
      ]

      return {
        loading,
        totalListTable,
        tableColumns,
      }
    }
}
</script>