import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{model:{value:(_vm.failedMessagesCauseModal),callback:function ($$v) {_vm.failedMessagesCauseModal=$$v},expression:"failedMessagesCauseModal"}},[_c(VCard,[_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.failed_messages_cause,"page":_vm.current_page,"loading":_vm.loading,"server-items-length":_vm.totalListTable,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"pagination":function($event){return _vm.changingMsgPage($event)}},scopedSlots:_vm._u([{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.contact)+" ")])]}},{key:"item.error",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.error)+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.phone)+" ")])]}}],null,true)}),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CERRAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }