<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          @keyup="handleSearch"
          placeholder="Buscar por nombre del evento de campaña"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <v-select
          style="width: 0px"
          class="mb-4 mr-2"
          :items="[
            { name: '-', value: -1 },
            { name: 'Pendiente', value: 0 },
            { name: 'Creado', value: 1 },
            { name: 'Fallido', value: 2 },
          ]"
          hide-details="true"
          v-model="statusFilterValue"
          label="Estado"
          item-text="name"
          item-value="value"
          @change="setFilter"
          outlined
          dense
        ></v-select>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            v-if="(getUserRole === 0 || 1) && getSelectedCompany !== 'all'"
            @click="$refs.campaign_form_modal.openModal()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Crear Campaña</span>
          </v-btn>
        </div>
      </v-card-text>
      <!--List-->
      <v-data-table
        :headers="tableColumns"
        :items="campaignEventListTable"
        :server-items-length="totalCampaignEventListTable"
        :page="current_page"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
        @update:page="changingCampaignEventsPage($event)"
        @update:items-per-page="changingCampaignEventsItemsPerPage($event)"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Eventos de Campaña</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

        <!--Date-->
        <template #[`item.created_at`]="{ item }">
          <span class="date-container">{{ item.created_at }}</span>
        </template>

        <!--Name-->
        <template #[`item.name`]="{ item }">
          <v-btn
            class="no-uppercase campaign-name-container pa-1 mr-2"
            text
            color="primary"
            min-width="0"
            max-height="28px"
            @click="$refs.campaign_event_details_modal.openModal(item.id)"
          >
            {{ item.name }}
          </v-btn>
        </template>

        <!--Status-->
        <template #[`item.status`]="{ item }">
          <v-btn
            class="no-uppercase status-container pa-2"
            depressed
            :color="statusColorParser(item.status.id)"
            max-height="26px"
            min-width="0"
          >
            <span>{{ item.status.label }}</span>
          </v-btn>
        </template>

        <!--Contacts-->
        <template #[`item.total_contacts`]="{ item }">
          <span class="date-container">{{ item.total_contacts }}</span>
        </template>

        <!-- Company -->
        <template #[`item.company_name`]="{ item }">
          <span class="date-container">{{ item.company_name }}</span>
        </template>
      </v-data-table>


      <!--Created campaign modal-->
      <template>
        <v-dialog v-model="showCreatedCampaign" width="550px">
          <v-card>
            <v-card-text>
              <h3 class="d-flex justify-center created-msg">
                {{ createdCampaignMessage }}
              </h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeCreatedCampaignModal()">
                CERRAR
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <CampaignFormModal
        ref="campaign_form_modal"
        @reload-campaign-list="getCampaignEvents()"
        @show-created-modal="openCreatedCampaignModal"
      />

      <CampaignEventDetailsModal 
        ref="campaign_event_details_modal"
      />
    </v-card>
  </div>
</template>

<script>
const debounce = require("debounce");
import { ref } from "@vue/composition-api";
import CampaignFormModal from "@/components/Campaigns/CampaignFormModal";
import CampaignEventDetailsModal from "@/components/Campaigns/CampaignEventDetailsModal"
import {
  mdiAccountMultipleOutline,
  mdiPause,
  mdiPlay,
  mdiDeleteOutline,
  mdiPlus,
  mdiContentCopy,
} from "@mdi/js";
import CampaignDetailsModal from "./CampaignDetailsModal.vue";

export default {
  components: {
    CampaignFormModal,
    CampaignEventDetailsModal,
    CampaignDetailsModal
},
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      stopedColor: "rgb(255, 76, 81)",
      pendingColor: "rgb(24, 103, 192)",
      creatingColor: "rgb(146, 149, 143)",
      completedColor: "rgb(86, 202, 0)",
      inProgressColor: "rgb(145, 85, 253)",
      pausedColor: "rgb(255, 180, 0)",
      statusFilterValue: -1,
      timer: null,
      showCreatedCampaign: false,
      createdCampaignMessage: null,
    };
  },
  mounted() {
    if (this.getSelectedCompany) {
      this.getCampaignEvents();
    }
  },
  methods: {

    handleSearch: debounce(function (e) {
      this.current_page = 1;
      this.getCampaignEvents();
    }, 400),

    changingCampaignEventsPage(e) {
      this.current_page = e;
      this.getCampaignEvents();
    },

    changingCampaignEventsItemsPerPage(e) {
      this.page_size = e;
      this.getCampaignEvents();
    },

    openCreatedCampaignModal(message) {
      this.createdCampaignMessage = message;
      this.showCreatedCampaign = true;
    },

    closeCreatedCampaignModal() {
      this.createdCampaignMessage = null;
      this.showCreatedCampaign = false;
    },

    resetTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getCampaignEvents();
      }, 1000 * 45);
    },

    setFilter() {
      this.current_page = 1;
      this.getCampaignEvents();
    },

    statusColorParser(status) {
      if (status === 0) return 'info';
      if (status === 1) return 'success';
      if (status === 2) return 'error';
    },

    async getCampaignEvents() {
      this.loading = true;
      try {
        const query = {
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
          company_id: this.getSelectedCompany,
          status: this.statusFilterValue,
        };

        const response = await this.$api.getCampaignEvents(query);
        this.campaignEventListTable = response.results;
        this.totalCampaignEventListTable = response.count;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.resetTimer();
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  computed: {
    getUserRole() {
      return this.$store.getters["session/getUserRole"]();
    },
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
    tableColumns() {
      let tableColumns = [
        { text: "Nombre", value: "name", align: "center", sortable: false },
        { text: "Fecha", value: "created_at", align: "center", sortable: false, width: '200px' },
        { text: "Estado", value: "status", align: "center", sortable: false },
        { text: "Cantidad de contactos", value: "total_contacts", align: "center", sortable: false },
      ];

      if(this.getSelectedCompany === 'all') {
        tableColumns.push({ text: "Empresa", value: "company_name", align: "center", sortable: false })
      }

      return tableColumns
    }
  },

  watch: {
    getSelectedCompany(newValue) {
      if (newValue) {
        this.current_page = 1;
        this.page_size = 10;
        this.getCampaignEvents();
      }
    },
  },
  setup() {
    const totalCampaignEventListTable = ref(1);
    const campaignEventListTable = ref([]);

    const loading = ref(false);
    const searchQuery = ref("");

    return {
      totalCampaignEventListTable,
      campaignEventListTable,
      loading,
      searchQuery,
      icons: {
        mdiAccountMultipleOutline,
        mdiPause,
        mdiPlay,
        mdiDeleteOutline,
        mdiPlus,
        mdiContentCopy,
      },
    };
  },
};
</script>

<style>
.no-uppercase {
  text-transform: unset !important;
}

.campaign-name-container span {
  font-weight: 600;
  font-size: 15px;
}

.campaign-participants-container {
  min-width: 0 !important;
  background-color: rgba(137, 43, 226, 0.2);
  color: rgba(137, 43, 226, 0.9);
  border: 1.5px solid rgb(130, 15, 236);
}

.campaign-participants-container span {
  font-size: 14px;
  color: rgba(137, 43, 226, 0.9);
}

.date-container {
  font-weight: 500;
  color: rgb(125, 125, 125);
}

.status-container {
  color: white !important;
  font-size: 13px;
}

.grouped-btn {
  border: none;
  border-radius: 0px;
}

.grouped-btn:first-child {
  border-radius: 10px 0 0 10px;
}

.grouped-btn:last-child {
  border-radius: 0 10px 10px 0;
}

.created-msg {
  overflow-wrap: break-word;
  font-weight: 500;
}
</style>