import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.showing_modal),callback:function ($$v) {_vm.showing_modal=$$v},expression:"showing_modal"}},[_c(VCard,[_c(VCardText,[_c(VForm,{ref:"speintax_form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.addText.apply(null, arguments)}}},[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{staticClass:"ps-0",attrs:{"cols":"8","md":"8"}},[_c(VTextField,{attrs:{"outlined":"","rules":[ !_vm.spintax_text_error || 'Campo requerido'],"dense":"","autocomplete":"off","hide-details":"","label":"Texto"},model:{value:(_vm.spintax_text),callback:function ($$v) {_vm.spintax_text=$$v},expression:"spintax_text"}})],1),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"2","md":"2"}},[_c(VBtn,{attrs:{"color":"primary","type":"submit"}},[_vm._v(" Agregar ")])],1)],1),_c(VRow,[_c('div',{staticClass:"spintax-container"},_vm._l((_vm.allTexts),function(text,idx){return _c('span',[_c(VBtn,{attrs:{"color":"error","icon":"","x-small":""},on:{"click":function($event){return _vm.removeText(idx)}}},[_c(VIcon,{attrs:{"size":"15"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_vm._v(" "+_vm._s(text)+" ")],1)}),0),_c(VBtn,{staticClass:"ml-9 mt-2",attrs:{"color":"error","outlined":"","disabled":!_vm.allTexts.length},on:{"click":function($event){return _vm.removeAll()}}},[_c(VIcon,{attrs:{"size":"15"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_vm._v(" Borrar todo ")],1)],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" CERRAR ")]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.allTexts.length < 2},on:{"click":function($event){return _vm.createSpintax()}}},[_vm._v(" OK ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }