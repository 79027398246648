<template>
  <v-dialog
    v-model="showingCampaignModal"
    width="700"
    scrollable
    persistent
    minHeight="1200"
  >
    <v-form ref="campaignForm" autocomplete="off" @submit.prevent="campaignOrEvent">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ copyCampaignItem ? "Copiar Campaña" : "Crear Campaña" }}
        </v-card-title>

        <v-tabs v-model="currentTab">
          <v-tab :value="0">Mensaje</v-tab>
          <v-tab v-if="has_google_account" :value="1">Evento</v-tab>
        </v-tabs>

        <v-card-text style="max-height: 500px">
          <v-tabs-items v-model="currentTab">
            <v-tab-item :value="0">
              <v-col cols="12" md="12" class="ps-0">
                <v-text-field
                  ref="campaign_name_ref"
                  v-model="campaign_name"
                  :rules="[(v) => !!v || 'Campo requerido']"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details
                  label="Nombre de la Campaña"
                />
              </v-col>
              <v-row class="mb-3">
                <v-col cols="12" md="12" class="py-0 mt-6">
                  <h3 class="text-l font-weight-medium d-flex align-center">
                    <v-icon class="mr-2">{{ icons.mdiAccountBox }}</v-icon>
                    Seleccionar contactos
                    <v-tooltip right v-if="radio_option === 'tag_option'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-0" color="primary" dense v-bind="attrs" v-on="on">
                          {{ icons.mdiInformationOutline }}
                        </v-icon>
                      </template>
                      <span>Solo se muestran los TAGs con contactos asociados.</span>
                    </v-tooltip>
                  </h3>
                </v-col>
                <v-col cols="12" md="12" class="py-0">
                  <v-radio-group
                    ref="radio_group"
                    class="mt-2"
                    v-model="radio_option"
                    :rules="[(v) => !!v || 'Campo requerido']"
                    @change="resetRadioOptions()"
                    row
                  >
                    <v-radio label="Por Tag" value="tag_option"></v-radio>
                    <v-radio label="Por Lote" value="batch_option"></v-radio>
                    <v-radio label="Digitar los números" value="numbers_option"></v-radio>
                  </v-radio-group>

                  <!--Numbers option-->
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-0"
                    v-if="radio_option === 'numbers_option'"
                  >
                    <v-list>
                      <v-list-item v-for="(input, k) in campaign_number" :key="k">
                        <v-row class="mt-2">
                          <v-col cols="3" md="3">
                            <v-select
                              id="selectorflags"
                              name="selectorflags"
                              v-model="input.selectorFlags"
                              :items="itemsPrefix"
                              label="Cod. Area"
                              :rules="[(v) => !!v || 'Caracteristica requerida']"
                              required
                              hide-details="auto"
                              outlined
                              dense
                              @change="changePrefix($event, k)"
                            ></v-select>
                          </v-col>
                          <v-col cols="9" md="9">
                            <v-text-field
                              v-model="input.number"
                              type="number"
                              outlined
                              :rules="[(v) => !!v || 'El número es necesario']"
                              dense
                              placeholder="Número de Whatsapp"
                              hide-details
                              label="Whatsapp"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="10" md="10">
                            <v-text-field
                              ref="name"
                              v-model="input.name"
                              :rules="[(v) => !!v || 'Nombre requerido']"
                              outlined
                              dense
                              autocomplete="off"
                              hide-details
                              label="Nombre del contacto"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" md="2">
                            <v-btn
                              icon
                              color="error"
                              @click="removeNumberInput(k)"
                              v-if="radio_option === 'numbers_option'"
                            >
                              <v-icon>{{ icons.mdiMinusCircleOutline }}</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-btn
                    icon
                    color="primary"
                    @click="addNumberInput()"
                    v-if="radio_option === 'numbers_option'"
                  >
                    <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
                  </v-btn>
                  <span
                    class="error--text error-msg"
                    v-if="campaign_number_msg && radio_option === 'numbers_option'"
                    >{{ campaign_number_msg }}</span
                  >
                  <!--Tag option-->
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-0 pb-0"
                    v-if="radio_option === 'tag_option'"
                  >
                    <v-combobox
                      v-model="campaign_tag"
                      :items="tagsSelectData"
                      :search-input.sync="searchQuery"
                      :rules="[(v) => !!v || 'Campo requerido']"
                      label="Seleccione uno o varios tags"
                      outlined
                      chips
                      deletable-chips
                      item-text="name"
                      :item-value="(item) => item"
                      dense
                      multiple
                    >
                      <template #item="data">
                        <v-list-item-content @click="searchQuery = ''">
                          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            No se encontraron <strong>Tags</strong>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-combobox>
                    <span
                      class="error--text error-msg"
                      v-if="campaign_tag_msg && radio_option === 'tag_option'"
                      >{{ campaign_tag_msg }}</span
                    >
                  </v-col>

                  <!--Batch option-->
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-0 pb-0"
                    v-if="radio_option === 'batch_option'"
                  >
                    <v-combobox
                      v-model="campaign_batch"
                      :items="batchsSelectData"
                      :search-input.sync="searchQuery"
                      :rules="[(v) => !!v || 'Campo requerido']"
                      label="Seleccione uno o varios Lotes de contactos"
                      outlined
                      chips
                      deletable-chips
                      item-text="name"
                      :item-value="(item) => item"
                      dense
                      multiple
                    >
                      <template #item="data">
                        <v-list-item-content @click="searchQuery = ''">
                          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            No se encontraron <strong>Lotes</strong>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-combobox>
                    <span
                      class="error--text error-msg"
                      v-if="campaign_tag_msg && radio_option === 'batch_option'"
                      >{{ campaign_tag_msg }}</span
                    >
                  </v-col>
                </v-col>

                <v-col cols="12" md="12" class="mb-3">
                  <h3 class="text-l font-weight-medium d-flex align-center ms-1">
                    <v-icon class="mr-2 mb-3">{{ icons.mdiCellphoneWireless }}</v-icon>
                    Seleccionar Canales
                  </h3>
                  <v-combobox
                    v-model="campaign_channels"
                    :items="channelSelectData"
                    :search-input.sync="searchChannelQuery"
                    :rules="[v => !!v || 'Campo requerido']"
                    label="Seleccione uno o varios canales"
                    outlined
                    chips
                    deletable-chips
                    item-disabled="disabled"
                    item-text="name"
                    :item-value="(item) => item"
                    dense
                    persistent-hint
                    hint="* Si no puede seleccionar algún canal, revise el estado y configuración de ese canal"
                    multiple
                  >
                    <template #item="data">
                      <v-list-item-content @click="searchChannelQuery = ''">
                        <v-list-item-title >{{ data.item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          No se encontraron <strong>Canales</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-combobox>
                  <span
                    class="error--text error-msg"
                    v-if="campaign_channels_msg"
                    >{{ campaign_channels_msg }}</span
                  >
                </v-col>
              </v-row>

              <v-row v-if="!copyCampaignItem">
                <v-col cols="12" md="12" class="pb-0">
                  <h3 class="text-l font-weight-medium d-flex align-center ms-1">
                    Mensaje
                  </h3>
                </v-col>
                <v-col cols="12" md="12" v-for="(input, k) in campaign_messages">
                  <v-row >
                    <v-col cols="4" md="4" class="pb-0">
                      <v-select
                        class="mb-4 mr-2"
                        :items="[
                          { name: '-', value: 0 },
                          { name: 'Texto', value: 1 },
                          { name: 'Archivo', value: 2 },
                          { name: 'VCard', value: 3 },
                        ]"
                        :rules="[input.type !== 0 || 'Campo requerido']"
                        hide-details="true"
                        v-model="input.type"
                        label="Tipo"
                        item-text="name"
                        item-value="value"
                        outlined
                        dense
                        @change="handleTypeChange(k)"
                      ></v-select>
                    </v-col>

                    <v-col cols="4" md="4" class="pb-0">
                      <v-text-field
                        v-model="input.order"
                        outlined
                        validate-on-blur
                        :rules="[(input.order > 0 ) || 'Campo requerido', !input.orderError || 'Deben tener diferente orden']"
                        type="number"
                        dense
                        autocomplete="off"
                        label="Orden"
                        @input="validateSameOrder($event, k)"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4" md="4" class="pb-0">
                      <v-btn
                        icon
                        color="error"
                        @click="removeMessageType(k)"
                        v-if="k !== 0"
                      >
                        <v-icon>{{ icons.mdiMinusCircleOutline }}</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col cols="12" md="12" class="py-0" v-if="input.type === 1">
                      <v-btn
                        color="secondary"
                        outlined
                        x-small
                        class="mb-3"
                        @click="
                          $refs.messages_templates_modal.openTemplatesModal(getSelectedCompany, k)
                        "
                      >
                        <span>Plantillas de mensajes</span>
                      </v-btn>
                      <v-btn
                        color="secondary"
                        outlined
                        x-small
                        class="mb-3 ml-3"
                        @click="previewMessage(k)"
                      >
                        Previsualización del mensaje
                      </v-btn>
                      <v-textarea
                        :prepend-inner-icon="icons.mdiMessageBulleted"
                        :ref="`campaign_text_ref_${k}`"
                        v-model="input.value"
                        :rules="[(v) => !!v || 'Escriba algún mensaje']"
                        counter="1000"
                        maxlength="1000"
                        placeholder="Mensaje"
                        :id="`textarea-input-campaign-${k}`"
                        outlined
                        dense
                        hint=""
                        hide-details
                        label="Mensaje"
                      ></v-textarea>

                      <div class="d-flex justify-space-between">
                        <EmojiPicker @insert-emoji="insertEmoji($event, k)" />

                        <div class="d-flex justify-end">
                          <v-btn
                            color="secondary"
                            outlined
                            x-small
                            class="mt-3 mr-3"
                            @click="$refs.spintax_modal.openModal(k)"
                          >
                            Spintax
                          </v-btn>

                          <v-select
                            :ref="`select_variables_ref_${k}`"
                            class="col-4 mt-3"
                            v-model="selectedVariable"
                            :items="variables"
                            @change="handleVariableSelection($event, k)"
                            label="Variables dinámicas..."
                            dense
                          ></v-select>
                        </div>

                      </div>
                    </v-col>
                    <v-col cols="12" md="12" class="pb-0" v-if="input.type === 2">
                      <v-file-input
                      label="Elija su archivo para adjuntar"
                      show-size
                      truncate-length="50"
                      :accept="acceptedFiles"
                      v-model="input.value"
                      :rules="[(input.value ? input.value.size < maxImgSize : true) || 'Archivo muy grande', !!input.value || 'Campo requerido']"
                      ></v-file-input>
                      <!-- <drag-and-drop
                        ref="drag_and_drop"
                        @files-to-send="bindFilesToSend($event, k)"
                        singular
                        v-if="!copyCampaignItem"
                      /> -->
                    </v-col>

                    <v-col cols="12" md="12" class="py-0" v-if="input.type === 3">
                      <v-radio-group
                        ref="vcard_radio_group"
                        class=""
                        v-model="input.vCardType"
                        :rules="[(v) => !!v || 'Campo requerido']"
                        @change="resetVCardRadio(input)"
                        row
                      >
                        <v-radio label="VCard estática" value="static_option"></v-radio>
                        <v-radio label="VCard dinámica" value="dynamic_option"></v-radio>
                      </v-radio-group>
                      <div v-if="input.vCardType === 'static_option'">
                        <div class="primary--text mb-2 font-weight-medium">Todos los contactos que pertenezcan a esta campaña recibirán la misma vCard</div>
                        <div class="d-flex">
                          <v-combobox
                            style="width:50%"
                            v-model="input.contact"
                            :items="contactsSelectData"
                            :search-input.sync="input.contactSearch"
                            :rules="[(v) => (!!v || input.noContact) || 'Campo requerido']"
                            label="Seleccione un contacto"
                            outlined
                            :disabled="input.noContact"
                            item-text="name"
                            :item-value="(item) => item"
                            @update:search-input="setContactsData(input.contactSearch)"
                            @change="getContactVariables(input)"
                            dense
                          >
                            <template #item="data">
                              <v-list-item-content @click="searchQuery = ''">
                                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
  
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  No se encontraron <strong>contactos</strong>
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-combobox>
                          <v-checkbox
                          v-model="input.noContact"
                          class="mt-2 ml-2 pt-0"
                          style="width:45%"
                          label="Ingresar datos manualmente"
                          @change="() => input.value = {}"
                          hide-details
                          ></v-checkbox>
                        </div>

                        <v-row v-if="input.contact && !input.noContact">
                          <v-col cols="12">
                            <v-chip color="primary" class="mt-1 mr-1" outlined v-for="(data, idx) in input.contactVariables" :key="idx">
                              {{ contactVariableParser(data) }}: {{ input.contact[data] }}
                            </v-chip>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.name"
                            :rules="[v => !!v || 'Campo requerido']"
                            label="Nombre"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.phone"
                            :rules="[v => !!v || 'Campo requerido']"
                            label="Teléfono"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.email"
                            label="Email"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.categories"
                            label="Categorías"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.note"
                            label="Nota"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.address"
                            label="Dirección"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="[
                              'Trabajo',
                              'Casa'
                            ]"
                            hide-details
                            v-model="input.value.address_type"
                            label="Tipo de dirección"
                            outlined
                            dense
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row v-if="input.noContact">
                          <v-col cols="6">
                            <v-text-field
                              v-model="input.value.name"
                              :rules="[(v) => !!v || 'Campo requerido']"
                              outlined
                              dense
                              autocomplete="off"
                              hide-details
                              label="Nombre"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="input.value.phone"
                              :rules="[(v) => !!v || 'Campo requerido']"
                              outlined
                              dense
                              autocomplete="off"
                              hide-details
                              type="number"
                              label="Teléfono"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="input.value.email"
                              outlined
                              dense
                              autocomplete="off"
                              hide-details
                              label="Email"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="input.value.categories"
                              outlined
                              dense
                              autocomplete="off"
                              hide-details
                              label="Categorías"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="input.value.note"
                              outlined
                              dense
                              autocomplete="off"
                              hide-details
                              label="Nota"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="input.value.address"
                              outlined
                              dense
                              autocomplete="off"
                              hide-details
                              label="Dirección"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="[
                              'Trabajo',
                              'Casa'
                            ]"
                            hide-details
                            v-model="input.value.address_type"
                            label="Tipo de dirección"
                            outlined
                            dense
                            ></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else-if="input.vCardType === 'dynamic_option'">
                        <div class="primary--text mb-2 font-weight-medium">
                          La vcard que recibiran los contactos se adaptará a la informacion que cada uno de ellos tiene almacenada
                        </div>
                        <v-row>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.name"
                            :rules="[v => !!v || 'Campo requerido']"
                            label="Nombre"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.phone"
                            :rules="[v => !!v || 'Campo requerido']"
                            label="Teléfono"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.email"
                            label="Email"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.categories"
                            label="Categorías"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.note"
                            label="Nota"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="input.contactVariables"
                            hide-details
                            v-model="input.value.address"
                            label="Dirección"
                            outlined
                            dense
                            >
                              <template #item="data">
                                {{ contactVariableParser(data.item) }}
                              </template>

                              <template #selection="data">
                                {{ contactVariableParser(data.item) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                            class="mb-4"
                            :items="[
                              'Trabajo',
                              'Casa'
                            ]"
                            hide-details
                            v-model="input.value.address_type"
                            label="Tipo de dirección"
                            outlined
                            dense
                            ></v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>

                </v-col>

                <v-col cols="4" md="4" class="pt-0">
                  <v-btn
                    icon
                    color="primary"
                    @click="addMessageType()"

                  >
                    <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <!--Date picker-->
                <v-col cols="12" md="12" class="py-0">
                  <h3 class="text-l font-weight-medium d-flex align-center">
                    <v-icon class="mr-2">{{ icons.mdiCalendarMonth }}</v-icon>
                    Fecha de inicio
                  </h3>
                </v-col>
                <v-col cols="8" md="8">
                  <v-text-field
                    label="Fecha"
                    type="date"
                    v-model="campaign_date"
                    :rules="[(v) => !!v || 'Campo requerido']"
                    :min="minDate"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row >
                <!--Send times-->
                <v-col cols="12" md="12" class="py-0">
                  <h3 class="text-l font-weight-medium d-flex align-center">
                    <v-icon class="mr-2">{{ icons.mdiClockTimeSevenOutline }}</v-icon>
                    Días y horario de envio de mensajes
                  </h3>
                </v-col>
                <v-col cols="12" md="12">
                  <v-list flat>
                    <v-list-item-group multiple>
                      <v-list-item
                        v-for="(item, i) in itemsDays"
                        :key="i"
                        :value="item"
                        class="d-flex"
                      >
                        <v-list-item-action>
                          <v-checkbox v-model="item.active" color="primary"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.day }}</v-list-item-title>
                        </v-list-item-content>
                        <div class="d-flex mt-4" style="width: 50%">
                          <v-menu
                            ref="time_start_refs"
                            v-model="item.timepicker_menu_start"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="item.send_time_start"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            :disabled="!item.active"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.send_time_start"
                                label="Desde"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                :rules="[(v) => !!v || 'Campo requerido']"
                                :disabled="!item.active"
                                outlined
                                dense
                                required
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="item.timepicker_menu_start"
                              v-model="item.send_time_start"
                              full-width
                              format="24hr"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                outlined
                                color="primary"
                                @click="$refs.time_start_refs[i].save(item.send_time_start)"
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-menu>
                          <v-menu
                            ref="time_end_refs"
                            v-model="item.timepicker_menu_end"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="item.send_time_end"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            :disabled="!item.active"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.send_time_end"
                                label="Hasta"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                :rules="[(v) => !!v || 'Campo requerido']"
                                :disabled="!item.active"
                                outlined
                                dense
                                required
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="item.timepicker_menu_end"
                              v-model="item.send_time_end"
                              full-width
                              format="24hr"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.time_end_refs[i].save(item.send_time_end)"
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-menu>
                        </div>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <span class="error--text error-msg" v-if="days_checkbox_msg">{{
                    days_checkbox_msg
                  }}</span>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item :value="1">
              <campaign-event-form eager ref="campaign_event_form"
                @send-event-order="sendCampaignEvent"
                />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>


        <v-card-actions>
          <span class="error--text error-msg" v-if="error_msg">{{ error_msg }}</span>
          <span class="error--text error-msg" v-if="currentTab === 1 && reached_event_limit">Se llegó al limite de eventos.</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal()"> CANCELAR </v-btn>
          <v-btn color="primary" :disabled="currentTab === 1 && reached_event_limit" type="submit">
            {{ copyCampaignItem ? "COPIAR CAMPAÑA" : "CREAR CAMPAÑA" }}
          </v-btn>
        </v-card-actions>

        <TemplatesList ref="messages_templates_modal" @insert-template="insertTemplate" />
      </v-card>
    </v-form>
    <template>
      <v-dialog v-model="showingMessagePreviewModal" max-width="500" eager>
        <v-card class="pa-3">
          <v-card-title class="d-flex justify-center"> Mensaje </v-card-title>
          <v-divider></v-divider>
          <v-card-text ref="message_to_preview"> </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showingMessagePreviewModal = false">
              CERRAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <alert-pop-up ref="alert_pop_up" />
    <spintax-modal ref="spintax_modal" @insert-spintax="insertSpintax"/>
  </v-dialog>
</template>

<script>
const debounce = require("debounce");
import { ref } from "@vue/composition-api";
import DragAndDrop from "@/components/Messages/DragAndDrop";
import EmojiPicker from "@/components/Campaigns/EmojiPicker";
import TemplatesList from "@/components/Templates/TemplatesList";
import AlertPopUp from "@/components/AlertPopUp.vue";
import CampaignEventForm from "@/components/Campaigns/CampaignEventForm";
import SpintaxModal from "@/components/Campaigns/SpintaxModal";

import {
  mdiMessageBulleted,
  mdiClockTimeFourOutline,
  mdiAccountBox,
  mdiClockTimeSevenOutline,
  mdiCalendarMonth,
  mdiPlusCircleOutline,
  mdiMinusCircleOutline,
  mdiInformationOutline,
  mdiCellphoneWireless
} from "@mdi/js";
export default {
  data: () => {
    return {
      campaign_messages: [{type: 0, value: null, order: 1, orderError: false}],
      searchQuery: "",
      searchChannelQuery: "",
      campaign_name: "",
      campaign_text: "",
      campaign_number: [
        { name: null, prefix: 549, number: null, selectorFlags: "🇦🇷 (+549)" },
      ],
      campaign_tag: [],
      campaign_batch: [],
      campaign_channels: [],
      campaign_date: null,
      send_time_start: null,
      send_time_end: null,
      radio_option: null,
      minDate: null,
      checkDays: false,
      tagsSelectData: [],
      batchsSelectData: [],
      channelSelectData: [],
      contactsSelectData: [],
      filesToSend: [],
      error_msg: null,
      time_error: null,
      copyCampaignItem: null,
      days_checkbox_msg: null,
      campaign_tag_msg: null,
      campaign_number_msg: null,
      campaign_channels_msg: null,
      time_start_refs: [],
      time_end_refs: [],
      itemsDays: [],
      showingMessagePreviewModal: false,
      messageToPreview: "",
      variables: [
        "nombre",
        "dni",
        "link1",
        "link2",
        "dato1",
        "dato2",
        "dato3",
        "dato4",
        "dato5",
        "dato6",
        "dato7",
        "dato8",
        "dato9",
        "dato10",
      ],
      selectedVariable: null,
      currentTab: null,
      has_google_account: false,
      reached_event_limit: false,
      maxImgSize: 1024 * 1024 * 100, // 100Mb.
    };
  },
  components: {
    DragAndDrop,
    EmojiPicker,
    TemplatesList,
    AlertPopUp,
    CampaignEventForm,
    SpintaxModal
  },
  methods: {
    // allowedTime(initial, final) {

    // let allowedHours = []
    // let initHours = []
    // let finalHours = []
    // let min = []
    // let fmin = []
    // initial ? [initHours, min] = initial.split(':') : '08'
    // final ? [finalHours, fmin] = final.split(':') : '21'
    // console.log('allowed times: < '+initHours)

    // for (let i = initHours; i < finalHours; i++) {
    //     allowedHours.push(i);
    // }
    // return allowedHours;
    // },

    //prevent enable/disable checkbox if clcik on some <v-time-picker>
    stopPropagation(event) {
      event.stopPropagation();
    },
    splitTime(str) {
      return str.split(":");
    },
    //take the dayItems inputs and use it for validations
    // validateTime(horaInicial, horaFinal ){
    //     var initialTime = Date.parse("1970-01-01T"+horaInicial+":00.000Z")
    //     var finalTime = Date.parse("1970-01-01T"+horaFinal+":00.000Z")
    //     let iH = this.splitTime(horaInicial)
    //     // let fH = this.splitTime(horafinal)
    //     console.warn(iH)
    //     // console.log(fH)
    //     if(initialTime >= finalTime) {
    //         this.time_error = true
    //         console.log('Error');
    //     } else {console.log('OK');}
    // },
    openModal(item_to_copy) {
      this.setTagsData();
      this.setChannelsData();
      this.setMinDate();
      this.itemsDays = this.defaultItemsDays();

      item_to_copy
        ? this.setItemToCopy(item_to_copy)
        : (this.showingCampaignModal = true);

      this.resetScroll();
    },
    insertTemplate(template) {
      const textarea = document.getElementById(`textarea-input-campaign-${template.key}`);
      if (textarea.selectionStart || textarea.selectionStart == "0") {
        let startPos = textarea.selectionStart;
        let endPos = textarea.selectionEnd;

        textarea.value =
          textarea.value.substring(0, startPos) +
          `${template.text}` +
          textarea.value.substring(endPos, textarea.value.length);
        textarea.selectionStart = startPos + template.text.length;
        textarea.selectionEnd = startPos + template.text.length;
      }
      this.campaign_messages[template.key].value = textarea.value;
    },
    closeModal() {
      this.resetForm();
      this.resetRadioOptions();
      if(this.$refs.campaign_event_form) this.$refs.campaign_event_form.reset()
      this.radio_option = null;
      this.error_msg = null;
      this.showingCampaignModal = false;
      this.minDate = null;
      this.copyCampaignItem = null;
      this.days_checkbox_msg = null;
      this.campaign_number_msg = null;
      this.campaign_tag_msg = null;
      this.campaign_channels_msg = null;
      this.itemsDays = this.defaultItemsDays;
      this.searchChannelQuery = ""

    },
    resetForm() {
      this.$refs.campaignForm.reset();
      this.filesToSend = [];
      this.campaign_messages = [{type: 0, value: null, order: 1, orderError: false}]
      this.checkDays = false;
    },
    resetRadioOptions() {
      if(this.radio_option === 'batch_option') this.setBatchsData()
      if(this.radio_option === 'tag_option') this.setTagsData()
      this.campaign_number = [
        { name: null, prefix: 549, number: null, selectorFlags: "🇦🇷 (+549)" },
      ];
      this.tagsSelectData = [];
      this.batchsSelectData = [];
    },
    resetVCardRadio(input) {
      input.contact = null
      this.getContactVariables(input)
      input.value = {}
      this.setContactsData()
    },
    bindFilesToSend($event, k) {
      this.campaign_messages[k].value = $event;
    },
    setMinDate() {
      const date = new Date();
      this.minDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];

      this.campaign_date = this.minDate
    },
    changePrefix(e, i) {
      const matches = e.match(/\d+/g);

      if (matches) this.campaign_number[i].prefix = Number(matches[0]);
    },
    addNumberInput() {
      this.campaign_number.push({
        name: null,
        prefix: 549,
        number: null,
        selectorFlags: "🇦🇷 (+549)",
      });
    },
    removeNumberInput(index) {
      this.campaign_number.splice(index, 1);
    },
    addMessageType() {
      this.campaign_messages.push({
        type: 0,
        order: this.campaign_messages.length + 1,
        value: null
      });
    },
    removeMessageType(index) {
      this.campaign_messages.splice(index, 1);
    },

    handleTypeChange(k) {
      this.campaign_messages[k].value = null
    },
    async setTagsData() {
      const query = {
        page: 1,
        page_size: 10,
        search: this.searchChannelQuery,
        company_id: this.getSelectedCompany,
      };
      if (this.getSelectedCompany) {
        const response = await this.$api.getTags(query);
        // console.log(response);
        this.tagsSelectData = response.results.filter((el) => el.contacts > 0);
        // this.campaign_tag = []
      }
    },
    async setBatchsData() {
      // console.trace()
      const query = {
        company_id: this.getSelectedCompany,
        page: 1,
        page_size: 10,
        search: this.searchQuery,
      };
      if (this.getSelectedCompany) {

        const response = await this.$api.getContactBatches(query);
        // console.log(response);
        this.batchsSelectData = response.results;
        // this.campaign_tag = []
      }
    },
    async setChannelsData(){
      const form = {
        company_id: this.getSelectedCompany,
        page: 1,
        page_size: 10,
        search: this.searchChannelQuery,
        campaign_creation: true,
      }
      // const filters = {
      //   enabled: -1,
      //   status: -1
      // }
      // console.log(form);
      try {
        const response = await this.$api.getChannels(form)

        this.channelSelectData = response
        this.channelSelectData.forEach(el => {
          if(!el.enabled) {
            el.disabled = true
          }
        })
        // console.log(this.channelSelectData);
      } catch (error) {
        console.log(error);
      }
    },
    async setContactsData(searchQuery) {
      // console.trace()
      const query = {
        company_id: this.getSelectedCompany,
        page: 1,
        page_size: 20,
        search: searchQuery ?? '',
      };
      if (this.getSelectedCompany) {

        const response = await this.$api.getContacts(query);
        // console.log(response);
        this.contactsSelectData = response.results;
      }
    },
    getContactVariables(input) {
      input.value = {}
      let excluded = ['id', 'prefix', 'full_number','company_id','company_name', 'tags', 'google_sync']
      input.contactVariables = input.contact ? Object.keys(input.contact).filter(el => !excluded.includes(el) && input.contact[el]) 
                                            : [
                                              'name',
                                              'phone',
                                              'dni',
                                              'email',
                                              "link1",
                                              "link2",
                                              "dato1",
                                              "dato2",
                                              "dato3",
                                              "dato4",
                                              "dato5",
                                              "dato6",
                                              "dato7",
                                              "dato8",
                                              "dato9",
                                              "dato10",
                                            ]
    },
    handleSearch: debounce(function (e) {
      if(this.radio_option === 'batch_option') this.setBatchsData()
      if(this.radio_option === 'tag_option') this.setTagsData()
    }, 400),
    handleChannelSearch: debounce(function (e) {
      this.setChannelsData()
    }, 400),
    resetScroll() {
      this.$refs.campaignForm ? (this.$refs.campaignForm.scrollTop = 0) : false;
    },
    setItemToCopy(item_to_copy) {
      const item = item_to_copy;
      if (item) {
        this.copyCampaignItem = item;
      }

      this.showingCampaignModal = true;
    },

    validateSameOrder($event, k) {
      let orders = []
      this.campaign_messages.forEach((el, idx) => {
        if(idx !== k) {
          orders.push(el.order)
        }
      })
      if(orders.includes($event)) {
        this.campaign_messages[k].orderError = true
      } else {
        this.campaign_messages[k].orderError = false
      }
    },

    validateAllInOrder() {
      let orders = []
      let inOrder = true

      this.campaign_messages.forEach(el => {
        orders.push(parseInt(el.order))
      })

      orders.sort((a,b)=>a-b)

      if(orders.includes(1)) {
        for ( var i = 0; i < ( orders.length - 1 ); i++ ) {

          if ( orders[i + 1] != orders[i] + 1) {

              inOrder = false;
          }
        }
      } else {
        inOrder = false
      }
      // console.log(orders, inOrder)
      return inOrder
    },

    validateAllFileInput() {
      let valid = false

      if(!this.copyCampaignItem) {
        this.campaign_messages.forEach(el => {
          if(el.type === 1) {
            valid = true
          }
        })
      } else {
        valid = true
      }

      return valid
    },

    async checkIfCompanyHasGoogle() {
      this.has_google_account = false
      try {
        const response = await this.$api.checkIfCompanyHasGoogle(this.getSelectedCompany)
        // console.log(response.status)
        this.has_google_account = response.data.has_google_account
      } catch (e) {
        console.log(e);
        // console.log(e.response.status);
      }
    },

    async checkEventLimit() {
      this.reached_event_limit = false
      try {
        const response = await this.$api.createCampaignEvent({company: this.getSelectedCompany})
        // console.log(response)
      } catch (e) {
        console.log(e.response.status);
        if(e.response.status === 403 ) {
          this.reached_event_limit = true
        }
      }
    },

    async sendCampaignEvent(form) {
      try {
       // console.log(eventForm)
        const response = await this.$api.createCampaignEvent(form)

        this.$refs.alert_pop_up.showPopUp({ message: 'Evento creado correctamente', type: 'success' })
        this.$emit("reload-campaign-list");
        this.closeModal();
      } catch(e) {
        console.log(e)
        if(e.response.status === 403) {
          this.$refs.alert_pop_up.showPopUp({ message: e.response.data.message, type: 'error' })
        } else if(e.response.data.message) {
          this.$refs.alert_pop_up.showPopUp({ message: e.response.data.message, type: 'error' })
        } else {
          this.$refs.alert_pop_up.showPopUp({ message: 'Ocurrió un error.', type: 'error' })
        }
      }
    },

    campaignOrEvent() {
      if(this.currentTab === 0) {
        this.createCampaign()
      }
      if(this.currentTab === 1) {
        this.$refs.campaign_event_form.sendEvent()
      }
    },

    async createCampaign() {
      this.campaign_number_msg = null;
      this.days_checkbox_msg = null;
      this.campaign_tag_msg = null;
      this.campaign_channels_msg = null;

      this.checkDays = this.itemsDays.some((item) => item.active);

      if (!this.checkDays && !this.copyCampaignItem) {
        this.days_checkbox_msg = "Es requerido seleccionar un día como mínimo.";
      }

      if (!this.campaign_tag.length && this.radio_option === "tag_option") {
        this.campaign_tag_msg = "Es requerido seleccionar un tag como mínimo.";
      }

      if (!this.campaign_number.length) {
        this.campaign_number_msg = "Es requerido ingresar un contacto como mínimo.";
      }

      if (!this.campaign_channels.length) {
        this.campaign_channels_msg = "Es requerido seleccionar un canal como mínimo.";
      }

      if (this.radio_option === "numbers_option") {
        this.campaign_number.forEach((el) => {
          if (!el.prefix || !el.number || !el.name) {
            this.campaign_number_msg = "Es requerido ingresar un contacto como mínimo.";
          }
        });
      }

      if(!this.validateAllInOrder()) {
        this.$refs.alert_pop_up.showPopUp({
          message: 'No se puede saltear números en el orden',
          type: "error",
        });
      }

      if(!this.validateAllFileInput()) {
        this.$refs.alert_pop_up.showPopUp({
          message: 'No se puede crear una campaña solo con archivos y/o VCards',
          type: "error",
        });
      }

      console.log('create')

      if (
        this.$refs.campaignForm.validate() &&
        !this.days_checkbox_msg &&
        !this.campaign_number_msg &&
        !this.campaign_tag_msg &&
        !this.campaign_channels_msg &&
        this.validateAllInOrder() &&
        this.validateAllFileInput()
      ) {
        const formData = new FormData();

        const campaign_tag_ids = this.campaign_tag.map((el) => el.id);

        //send only active checkboxes
        const itemsDateTime = this.itemsDays
          .filter((item) => item.active === true)
          .map((item) => ({
            number: item.number,
            send_time_start: item.send_time_start,
            send_time_end: item.send_time_end,
          }));

        this.campaign_messages.forEach(el => {
          if(el.type === 2) {
            formData.append('files', el.value)
          }
        })


        const form = {
          name: this.campaign_name,
          messages: [],
          contacts:
            this.radio_option === "numbers_option"
              ? JSON.stringify(this.campaign_number)
              : [],
          start_date: this.campaign_date,
          // date_time: JSON.stringify(itemsDateTime),
          company_id: this.getSelectedCompany,
          channels: this.campaign_channels.map((el) => el.id)
        };

        let form_messages = []

        this.campaign_messages.forEach(el => {
          if(el.type === 1) {
            form_messages.push({message: el.value, order: el.order})
          }
          if(el.type === 2) {
            form_messages.push({order: el.order})
          }
          if(el.type === 3) {
            if(el.vCardType === 'static_option') {
              el.contact && !el.noContact ?
              form_messages.push({
                order: el.order,
                contact_id: el.contact.id,
                vcard: {
                  name: el.value.name ? `{{contact.${el.value.name}}}` : '',
                  phone: el.value.phone ? `{{contact.${el.value.phone}}}` : '',
                  email: el.value.email ? `{{contact.${el.value.email}}}` : '',
                  categories: el.value.categories ? `{{contact.${el.value.categories}}}` : '',
                  note: el.value.note ? `{{contact.${el.value.note}}}` : '',
                  address: el.value.address ? `{{contact.${el.value.address}}}` : '',
                  address_type: el.value.address_type ? el.value.address_type ?? '' : '',
                }
              })
              : form_messages.push({
                order: el.order,
                vcard: {
                  name: el.value.name,
                  phone: el.value.phone,
                  email: el.value.email ?? '',
                  categories: el.value.categories ?? '',
                  note: el.value.note ?? '',
                  address: el.value.address ?? '',
                  address_type: el.value.address_type ?? '',
                }
              })
            }else if(el.vCardType === 'dynamic_option') {
              form_messages.push({
                order: el.order,
                vcard: {
                  name: el.value.name ? `{{contact.${el.value.name}}}` : '',
                  phone: el.value.phone ? `{{contact.${el.value.phone}}}` : '',
                  email: el.value.email ? `{{contact.${el.value.email}}}` : '',
                  categories: el.value.categories ? `{{contact.${el.value.categories}}}` : '',
                  note: el.value.note ? `{{contact.${el.value.note}}}` : '',
                  address: el.value.address ? `{{contact.${el.value.address}}}` : '',
                  address_type: el.value.address_type ? el.value.address_type ?? '' : '',
                }
              })
            }
          }
        })

        form.messages = JSON.stringify(form_messages)

        if(!this.copyCampaignItem) form.date_time= JSON.stringify(itemsDateTime)
        if(this.radio_option === "tag_option") form.tag = JSON.stringify(this.campaign_tag.map((el) => el.id))
        if(this.radio_option === "batch_option") form.batches = JSON.stringify(this.campaign_batch.map((el) => el.id))


        for (const key in form) {
          formData.set(key, form[key]);
        }

        // console.log(formData)


        // const copyForm = {
        //   name: this.campaign_name,
        //   tag: this.radio_option === "tag_option" ? campaign_tag_ids : [],
        //   contacts:
        //     this.radio_option === "numbers_option"
        //       ? JSON.stringify(this.campaign_number)
        //       : [],
        //   start_date: this.campaign_date,
        //   company_id: this.getSelectedCompany,
        // };

        // const copyFormData = new FormData();
        // for (const key in copyForm) {
        //   copyFormData.set(key, copyForm[key]);
        // }
        // console.log(form);
        try {
          const response = this.copyCampaignItem
            ? await this.$api.copyCampaign(formData, this.copyCampaignItem.id)
            : await this.$api.createCampaign(formData);
          this.$emit("reload-campaign-list");
          this.$emit("show-created-modal", response.data.message);
          this.closeModal();
        } catch (e) {
          console.log(e);
          if (e?.response?.status === 403) {
            this.error_msg = e?.response?.data?.message;
          } else {
            this.$refs.alert_pop_up.showPopUp({
              message: 'Ocurrió un error.Por favor intente nuevamente más tarde',
              type: "error",
            });
          }
        }
      }
    },
    handleVariableSelection(variable, k) {
      const textarea = document.getElementById(`textarea-input-campaign-${k}`);
      if (textarea.selectionStart || textarea.selectionStart == "0") {
        var startPos = textarea.selectionStart;
        var endPos = textarea.selectionEnd;
        textarea.value =
          textarea.value.substring(0, startPos) +
          `{{${variable}}}` +
          textarea.value.substring(endPos, textarea.value.length);
        textarea.selectionStart = startPos + variable.length + 4;
        textarea.selectionEnd = startPos + variable.length + 4;
      }
      this.campaign_messages[k].value = textarea.value;
      // console.log(this.$refs[`select_variables_ref_${k}`][0])
      this.$refs[`select_variables_ref_${k}`][0].reset();
      this.$refs[`select_variables_ref_${k}`][0].blur();
      this.$refs[`campaign_text_ref_${k}`][0].focus();
      // this.MessageWithVariables = `${this.campaign_text} <span style="color: blue">${variable}</span>`;
    },
    insertEmoji(emoji, k) {
      const textarea = document.getElementById(`textarea-input-campaign-${k}`);
      if (textarea.selectionStart || textarea.selectionStart == "0") {
        var startPos = textarea.selectionStart;
        var endPos = textarea.selectionEnd;
        textarea.value =
          textarea.value.substring(0, startPos) +
          `${emoji}` +
          textarea.value.substring(endPos, textarea.value.length);
        textarea.selectionStart = startPos + emoji.length;
        textarea.selectionEnd = startPos + emoji.length;
      }
      this.campaign_messages[k].value = textarea.value;
    },
    insertSpintax(spintax) {
      const textarea = document.getElementById(`textarea-input-campaign-${spintax.key}`);
      if (textarea.selectionStart || textarea.selectionStart == "0") {
        let startPos = textarea.selectionStart;
        let endPos = textarea.selectionEnd;
        textarea.value =
          textarea.value.substring(0, startPos) +
          `${spintax.text}` +
          textarea.value.substring(endPos, textarea.value.length);
        textarea.selectionStart = startPos + spintax.text.length;
        textarea.selectionEnd = startPos + spintax.text.length;
      }
      this.campaign_messages[spintax.key].value = textarea.value;
    },
    defaultItemsDays() {
      return [
        {
          day: "Lunes",
          number: 0,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Martes",
          number: 1,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Miércoles",
          number: 2,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Jueves",
          number: 3,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Viernes",
          number: 4,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Sábado",
          number: 5,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
        {
          day: "Domingo",
          number: 6,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
      ];
    },
    async previewMessage(k) {
      try {
        const campaign_tag_ids = this.campaign_tag.map((el) => el.id);
        const batches_ids = this.campaign_batch.map((el) => el.id)
        const form = {
          message: this.campaign_messages[k].value,   // tag: this.radio_option === "tag_option" ? JSON.stringify(campaign_tag_ids) : null,
        };
        if(this.radio_option === "tag_option") form.tag = JSON.stringify(campaign_tag_ids);
        if(this.radio_option === "batch_option") form.batches = batches_ids

        const response = await this.$api.previewMessage(form);
        if (response.status === 200) {
          this.messageToPreview = response.data.message.replace(/\n/g, "<br />");
          this.$refs.message_to_preview.innerHTML = this.messageToPreview;
        }
        this.showingMessagePreviewModal = true;
      } catch (e) {
        console.log(e);
        if (e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({
            message: e?.response?.data?.message,
            type: "error",
          });
        }
      }
    },
    contactVariableParser(item) {
      if(item === 'name') return 'nombre'
      if(item === 'phone') return 'teléfono'
      return item
    }
  },
  watch: {
    searchQuery(val) {
      if(val !== null){
        // console.log('handle search: ', val);
        this.handleSearch();
      } else {
        this.searchQuery = ''
      }
    },
    searchChannelQuery(val) {
      if(val !== null){
        // console.log('handle search: ', val);
        this.handleChannelSearch();
      }
    },
    getSelectedCompany(newValue) {
      if (newValue !== 'all') {
        this.checkIfCompanyHasGoogle()
        this.checkEventLimit()
      }
    }
  },
  computed: {
    MessageWithVariables: {
      get() {
        return this.campaign_text;
      },
      set(value) {
        this.campaign_text = value;
      },
    },
    getUserRole() {
      return this.$store.getters["session/getUserRole"]();
    },
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
    acceptedFiles() { return process.env.VUE_APP_ACCEPTED_FILES },
  },
  mounted() {
    this.itemsDays = this.defaultItemsDays;
    if(this.getSelectedCompany && this.getSelectedCompany !== 'all') {
      this.checkIfCompanyHasGoogle()
      this.checkEventLimit()
    }
  },
  setup() {
    const showingCampaignModal = ref(false);
    const itemsPrefix = [
      "🇦🇷 (+549)",
      "🇧🇷 (+55)",
      "🇧🇴 (+591)",
      "🇨🇱 (+56)",
      "🇨🇴 (+57)",
      "🇪🇨 (+593)",
      "🇲🇽 (+52)",
      "🇵🇪 (+51) ",
      "🇵🇾 (+595)",
      "🇺🇾 (+598)",
      "🇻🇪 (+58)",
    ];

    return {
      showingCampaignModal,
      itemsPrefix,
      icons: {
        mdiMessageBulleted,
        mdiClockTimeFourOutline,
        mdiAccountBox,
        mdiClockTimeSevenOutline,
        mdiCalendarMonth,
        mdiPlusCircleOutline,
        mdiMinusCircleOutline,
        mdiInformationOutline,
        mdiCellphoneWireless
      },
    };
  },
};
</script>
